import { PersonalDetailsFormContainer, PersonalDetailsFormGroup, InputCapStyle } from "@components/PersonalDetailsForm/PersonalDetailsForm.styles";
import { BasketResponseModel } from "@models/Basket/BasketResponseModel";
import { Address, Checkout } from "@models/Checkout/Checkout";
import { PersonalDetailsFormContentModel } from "@models/PersonalDetailsForm/PersonalDetailsFormContentModel";
import { AddressFinderService } from "@services/AddressFinderService";
import { EmailValidationService } from "@services/EmailValidationService";
import { setBasketAttribute, setBasketAttributes } from "@services/SalesRecoveryService";
import { DragTextInput, FormikFieldEmailValidationInput, KpFormDropdown, KpFormErrorMessageLeft, KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms";
import { H2, H5, Legal, Paragraph1 } from "@styles/Global.styles";
import theme from "@styles/theme";
import { Formik } from "formik";
import { connect as formikConnect } from "formik"
import { navigate } from "gatsby";
import React, { SyntheticEvent } from "react";
import { Form, Grid, Icon, Label, List, Responsive, } from "semantic-ui-react";
import _ from "lodash"
import { FileUploadInput, ImageUploadWrapper, AddressFinderFormGroup, AddressFinderResultItem, AddressSearchAutoComplete, AddressSearchTitleSection, AddressToggerLink, FileUploadBigWrapper, InlineEmailValidatingLoader, NameNoticeStyle, PersonalDetailsButtonContainer, PersonalDetailsContainerLink, PersonalDetailsDOBContainer, PersonalDetailsManualAddressContainer, PersonalDetailsManualAddressContainerHeading, SubTitleStyle, InputWrapper, TabStyle, PlateOptionsWrapper, TextInputText, ButtonText, TextInputSub, FieldBetweenStyle, UploadResultStyle, ListItemStyle, UploadFileErrorStyle, TabWrapper, PaymentOptionRadioButton, TabContentWrap, TabContentOptionStyle } from "./OwnerDetailsForm.style";
import { Days, Months, Years } from "@utils/DateDropdownValues";
import moment from "moment"
import { KpButton } from "@elements/index";
import { dealerDetailsValidationSchema } from "./DealerDetailsForms.validationSchema";
import { UploadFileType } from "@utils/ListingTypes";
import { Storage } from "aws-amplify"
import { padLeadingZeros } from "@utils/Helpers";
import { AwsService } from "@services/AwsService";
import { AwsStsCredentials } from "@services/Types";
const { v4: uuidv4 } = require("uuid")
const initialUploadingImages: UploadFileType[] = []
import { DEALER_CHECKOUT_AWS } from "@utils/Configuration"
import { getCurrentCheckout } from "@redux/localStorage/checkout";
import { ReCaptchaThreshold, VOSAFileSizeLimit } from "@utils/Constant";
import { ExpendIndicatorIcon } from "@components/ImageContentTab/ImageContentTab.style";
import ReCaptcha from "@utils/reCaptcha";
import { ReCaptchaService } from "@services/ReCaptchaService";
import { TransactionTypesSetting } from "@models/ProductModels";
interface OwnerDetailsProps {
    apiUrl: string,
    emailValidationToken: string,
    addressValidationToken: string,
    reCaptchaSiteKey: string,
    reCaptchaSiteKeyV2: string
    checkout: Checkout,
    basket: BasketResponseModel
    content: PersonalDetailsFormContentModel
    updateOwnerDetails: (personalDetails: Checkout) => void
    updateOwnerAddress: (owner: Address) => void
}

interface OwnerDetailsState {
    ownershipType: string
    manualAddress: boolean
    searchingAddress: boolean
    addressResults: Array<any>
    addressValue: string
    validatingOwnerEmail: boolean
    validatingApplicantEmail: boolean
    currentOwnerEmail: string
    ownerEmailIsValid?: boolean
    uploadingImages: UploadFileType[]
    showExistingPlate: boolean
    showNewPlate: boolean
    AwsCredentials?: AwsStsCredentials
    DealerKey?: string
    uploadFileError? : string
    reCaptchaToken?: string
    reCaptchaError: string
}

function OnSubmitValidationError(props: any) {
    const { callback, formik } = props

    const effect = () => {
        if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
            callback(formik)
        }
    }
    React.useEffect(effect, [formik.submitCount, formik.isSubmitting])

    return null
}

export const SubmitValidationError = formikConnect(OnSubmitValidationError)

export const addressResultRender = ({ title }: { title: string }) => (
    <AddressFinderResultItem>{title}</AddressFinderResultItem>
)

class OwnerDetailsForm extends React.Component<OwnerDetailsProps, OwnerDetailsState> {

    debouncedFn: any

    constructor(props: OwnerDetailsProps) {
        super(props)
        this.state = {
            manualAddress: false,
            ownershipType: "",
            searchingAddress: false,
            addressResults: [],
            addressValue: this.props.checkout.ownerAddress.fullAddress,
            validatingOwnerEmail: false,
            validatingApplicantEmail: false,
            currentOwnerEmail: this.props.checkout.ownerDetails.email,
            uploadingImages: initialUploadingImages,
            showExistingPlate: true,
            showNewPlate: false,
            uploadFileError: "",
            reCaptchaToken: undefined,
            reCaptchaError: ""
        }

    }

    initialStatus = {
        isSubmitting: false,
        isSubmitted: false
    }
    componentDidMount() {

        //check if dealer order, load aws credentials info if dealer order
        if (this.props.checkout.isDealerOrder && this.props.checkout.dealerDetails) {
            var userKey = padLeadingZeros(this.props.checkout.dealerDetails?.dealerId, 4) + padLeadingZeros(this.props.checkout.dealerDetails?.salesPersonId, 4);
            AwsService.getStsToken(this.props.apiUrl, userKey).then(result => {
                this.setState({ AwsCredentials: result, DealerKey: userKey });
            })
            var currentImages: UploadFileType[] = []
            this.props.checkout.vehicleDetails.vosa.map(item => {

                currentImages.push({
                    name: item.split('/')[1].slice(37),
                    id: item.split('/')[1].slice(0, 36),
                    fileType: item.slice(-5).toLocaleLowerCase() == ".pdf" ? "application/pdf" : "image/png",
                    key: item.split('/')[1],
                    uploaded: true
                })
            })
            this.setState({uploadingImages: currentImages})
        }
    }

    updatePhoneNumber = (phone: string) => {
        if (!phone.startsWith('+64')){
          if (phone.startsWith('0064')){
            phone = phone.replace('0064', '+64 ')
          }
          if(phone.startsWith('0')){
            phone = phone.replace('0', '+64 ')
          }
          if(!phone.startsWith('+64')) {
            phone = "+64 ".concat(phone);
          }
        } else{
            if (!phone.startsWith('+64 ')){
                phone = phone.replace('+64', '+64 ')
            }
        }
        return phone
      }

    submitForm = async (values: any, actions: any, execute: () => Promise<string>, widgetV2: number | undefined) => {
        if (!this.state.searchingAddress && !this.state.validatingOwnerEmail) {

            actions.setStatus({
                isSubmitting: true,
                isSubmitted: false
            })

            let recaptcha_v2: HTMLElement | null
            if(document){
                recaptcha_v2 = document.getElementById("g-recaptcha_dealerDetails-v2")
            } else {
                return
            }

            if(recaptcha_v2 && recaptcha_v2.style.display == "block"){
                const token = window.grecaptcha.getResponse(widgetV2)
                ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v2").then(async (response: any) => {
                    if (response && response.data.Success)
                    {
                        this.setState({reCaptchaError: ""})
                        this.processSubmit(values)
                    } else {
                        actions.setStatus({
                        isSubmitting: false,
                        isSubmitted: false
                        })
                        this.setState({reCaptchaError: "Please tick checkbox correctly"})
                        return false
                    }
                })
            } else {
                const token = await execute()
                this.setState({ reCaptchaToken: token })
                ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v3").then(async (response: any) => {
                    if (response.status != 200) return 'recaptcha validate error'
                    if (response.data.Data && (!response.data.Data.success || response.data.Data.score < ReCaptchaThreshold)) {
                        console.log(response.data.Data.score)
                        actions.setStatus({
                            isSubmitting: false,
                            isSubmitted: false,
                        })
                        if (document && recaptcha_v2){
                            recaptcha_v2!.style.display = "block"
                        }
                        return false
                    } else {
                        console.log(response.data.Data.score)
                        this.processSubmit(values)
                    }
                })
            }
        } else {
            actions.setSubmitting(false)
        }
    }

    processSubmit = (values: any) => {
        if (values.ownerDetails?.phone){
            values.ownerDetails.phone = this.updatePhoneNumber(values.ownerDetails?.phone)
          }
          if (values.applicantDetails?.phone){
            values.applicantDetails.phone = this.updatePhoneNumber(values.applicantDetails?.phone)
          }

        values.vehicleDetails.vosa = getCurrentCheckout().vehicleDetails.vosa
        this.props.updateOwnerDetails(values)

        const payload = this.createPayload(values)
        if (this.props.basket.basketId) {
            setBasketAttributes(
              this.props.apiUrl,
              this.props.basket.basketId,
              payload
            )
        }

        if (this.props.checkout.manufactureNow) {
            navigate("/delivery-address-selection/")
        } else {
            navigate("/review-payment/")
        }
    }

    createPayload = (values: any) => {
        let payload =  [
          { AttributeName: "promoSubscribed",
            Value: values.promoSubscribed == true ? "true" : "false"
          },{
            AttributeName: "Checkout.Owner.Phone",
            Value: values.ownerDetails?.phone
          },{
            AttributeName: "Checkout.Owner.Email",
            Value: values.ownerDetails?.email
          },{
            AttributeName: "Checkout.Applicant.FirstName",
            Value: values.dealerDetails?.salesPersonName.split(' ')[0]
          },{
            AttributeName: "Checkout.Applicant.LastName",
            Value: values.dealerDetails?.salesPersonName.substring(values.dealerDetails?.salesPersonName.split(' ')[0].length),
          },{
            AttributeName: "Checkout.Applicant.Email",
            Value: values.dealerDetails?.salesPersonEmail
          }]

          if (values.manufactureNow){
            payload.push({
              AttributeName: "Checkout.Owner.Address3",
              Value: values.ownerAddress?.street
            },{
              AttributeName: "Checkout.Owner.City",
              Value: values.ownerAddress?.city
            },{
              AttributeName: "Checkout.Owner.PostCode",
              Value: values.ownerAddress?.postCode
            },{
              AttributeName: "Checkout.Delivery.VehicleMake",
              Value: values.vehicleDetails?.vehicleMake
            },{
              AttributeName: "Checkout.Delivery.VehicleModel",
              Value: values.vehicleDetails?.vehicleModel
            })

            if(values.ownerAddress.building && values.ownerAddress.building.trim()){
              payload.push({
                AttributeName: "Checkout.Owner.Address1",
                Value: values.ownerAddress?.building
              })
            }
            if(values.ownerAddress.unit && values.ownerAddress.unit.trim()){
              payload.push({
                AttributeName: "Checkout.Owner.Address2",
                Value: values.ownerAddress?.unit
              })
            }
            if(values.ownerAddress.fullAddress && values.ownerAddress.fullAddress.trim()){
              payload.push({
                AttributeName: "Checkout.Owner.StreetAddressFull",
                Value: values.ownerAddress?.fullAddress
              })
            }
            if(values.vehicleDetails.driverLicense && values.vehicleDetails.driverLicense.trim()){
                payload.push({
                  AttributeName: "Checkout.Delivery.LicenceNo",
                  Value: values.vehicleDetails?.driverLicense
                })
            }
            if(values.vehicleDetails.dob){
                payload.push({
                  AttributeName: "Checkout.Owner.BirthDate",
                  Value: values.vehicleDetails?.dob
                })
            }
            if(values.vehicleDetails.existingCombination && values.vehicleDetails.existingCombination.trim()){
                payload.push({
                  AttributeName: "Checkout.Delivery.ExistingPlate",
                  Value: values.vehicleDetails?.existingCombination
                })
              }
          }

          if (values.ownershipType.toLowerCase() == "company"){
            payload.push({
              AttributeName: "Checkout.Owner.CompanyName",
              Value: values.companyDetails?.companyOwner
            })
          } else {
            payload.push({
                AttributeName: "Checkout.Owner.FirstName",
                Value: values.ownerDetails?.firstName,
              },{
                AttributeName: "Checkout.Owner.LastName",
                Value: values.ownerDetails?.lastName,
              },{
                AttributeName: "Checkout.Owner.Title",
                Value: values.ownerDetails?.title,
              },)
          }
          return payload
      }

    submitBasketAttributeValue = (attributeName: string, attributeValue: string, errors?: any
    ) => {
        if (this.props.basket.basketId) {
            setBasketAttribute(
                this.props.apiUrl,
                this.props.basket.basketId,
                attributeName,
                attributeValue
            )
        }
    }

    onSubmitValidationError = (event: any) => {
        console.log("error on validate submission")
        console.log(event)
    }

    validateOwnerEmailAddress = (value: any) => {
        if (this.state.currentOwnerEmail !== value) {
          this.setState({
            currentOwnerEmail: value,
            validatingOwnerEmail: true,
            ownerEmailIsValid: undefined,
          })
          let error
          return EmailValidationService.validate(
            this.props.emailValidationToken,
            value,
            true
          ).then((result: any) => {
            if (result.success == true) {
                setTimeout(() => {
                    this.setState({
                      validatingOwnerEmail: false,
                      ownerEmailIsValid: true,
                    }, () => {
                    })
                }, 800);
            } else {
              error = "Invalid Email"
              this.setState({
                validatingOwnerEmail: false,
                ownerEmailIsValid: false,
              })
              return error
            }
          })
        } else if (this.state.ownerEmailIsValid == false) {
          return "Invalid Email"
        }
      }

    handleAddressSearchChange = (e: SyntheticEvent, data: any) => {
        this.setState({ addressValue: data.value })
        if (!this.debouncedFn) {
            this.debouncedFn = _.debounce(this.searchAddress, 500)
        }
        this.debouncedFn()
    }

    searchAddress = () => {
        const input = this.state.addressValue
        this.setState({ searchingAddress: true })

        const token = this.props.addressValidationToken
        AddressFinderService.experianAddressSearch(token, input).then(
            (result: any) => {
                const matchedAddresses = result.map((item: any) => ({
                    title: item.text,
                    id: item.global_address_key
                }))
                this.setState({
                    addressResults: matchedAddresses,
                    searchingAddress: false
                })
            }
        )
    }

    toggleManualAddressInput() {
        this.setState({ manualAddress: !this.state.manualAddress })
    }

    toggleManualAddress = (setFieldValue: any) => {
        const isManualAddress = !this.props.checkout.ownerAddress.isManualAddress

        setFieldValue("ownerAddress.isManualAddress", isManualAddress)
        setFieldValue("ownerAddress.fullAddress", "")
        setFieldValue("ownerAddress.unit", "")
        setFieldValue("ownerAddress.city", "")
        setFieldValue("ownerAddress.postCode", "")
        setFieldValue("ownerAddress.street", "")
        setFieldValue("ownerAddress.suburb", "")
        this.setState({
            addressValue: "",
        })

        const ownerAddress = this.props.checkout.ownerAddress
        ownerAddress.isManualAddress = isManualAddress
        this.props.updateOwnerAddress(ownerAddress)
    }

    upload = async (event: any) => {
        if (event.target.files && event.target.files.length > 0) {

            Array.from(event.target.files, async (file: any) => {
                const fileId = uuidv4()
                const toUploadFile: UploadFileType = {
                    name: file.name,
                    id: fileId,
                    fileType: file.type,
                    key: fileId + '-' + file.name,
                }
                if (file.size > VOSAFileSizeLimit){
                    this.setState({uploadFileError: "File is large than 2MB."})
                    return
                }
                this.setState(prevState => { return { ...prevState, uploadingImages: [...prevState.uploadingImages, toUploadFile] } });

                if (this.state.AwsCredentials && this.state.DealerKey) {
                    const filePath = this.state.DealerKey + "/" + toUploadFile.key
                    await AwsService.uploadToS3(this.state.AwsCredentials, this.state.DealerKey, file, toUploadFile.key, DEALER_CHECKOUT_AWS.AWSS3.bucket).then(res => {
                        if (res.statusCode == 200) {
                            this.props.checkout.vehicleDetails.vosa.push(filePath)
                            this.props.updateOwnerDetails(this.props.checkout)
                            this.setState(prevState => {
                                return {
                                    ...prevState,
                                    uploadingImages: prevState.uploadingImages.map(img => {
                                        const item = img.id == fileId
                                            ? {
                                                ...img, uploaded: true
                                            } : img;
                                        return item;
                                    })
                                }
                            });
                            this.setState({uploadFileError: ""})
                        } else{
                            this.setState({uploadFileError: "Upload File Error! Status Code:" + res.statusCode})
                        }
                    })
                } else {
                    this.setState({uploadFileError: "Credentials Error!"})
                }
            })
        }
    }

    deleteFile = async (fileId?: string) => {
        if (fileId) {
            const file = this.state.uploadingImages.find(s => s.id == fileId);
            if (this.state.AwsCredentials && this.state.DealerKey && file) {
                const filePath = this.state.DealerKey + "/" + file.key
                await AwsService.deleteFromS3(this.state.AwsCredentials, this.state.DealerKey, file.key, DEALER_CHECKOUT_AWS.AWSS3.bucket).then(res => {
                    if (res.statusCode == 200) {
                        const vosa = getCurrentCheckout().vehicleDetails.vosa.filter(e => e !== filePath)
                        this.props.checkout.vehicleDetails.vosa = vosa
                        this.props.updateOwnerDetails(this.props.checkout)
                        this.setState(prevState => {
                            return {
                                ...prevState,
                                uploadingImages: prevState.uploadingImages.filter(s => s.id !== file.id)
                            }
                        });
                        this.setState({uploadFileError: ""})
                    }else{
                        this.setState({uploadFileError: "Delete File Error! Status Code:" + res.statusCode})
                    }
                })
            }else {
                this.setState({uploadFileError: "Credentials Error!"})
            }
        }
    }

    ChangeVehicleRegistration = (showExistingPlate: boolean) => {
        this.setState({
            showExistingPlate: showExistingPlate,
            showNewPlate: !showExistingPlate
        })
    }

    render() {
        const { content } = this.props
        console.log(this.state.uploadingImages);
        var checkout = this.props.checkout;
        var disableEditExistingPlate = false;
        if (this.props.basket.basketLines && this.props.basket.basketLines.length == 1 && this.props.checkout.manufactureNow && this.props.basket.basketLines[0].transactionTypeId == TransactionTypesSetting.Upgrade.id){
            checkout.vehicleDetails.existingCombination = this.props.basket.basketLines[0].combination
            disableEditExistingPlate = true;
        }
        return (<PersonalDetailsFormContainer>
            <H2>Owner Details</H2>
            <ReCaptcha action="dealerDetails" reCaptchaSiteKey={this.props.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}>{captcha => (
            <Formik initialValues={checkout}
                validationSchema={dealerDetailsValidationSchema}
                initialStatus={this.initialStatus}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values: any, actions: any) =>
                    this.submitForm(values, actions, captcha.execute, captcha.widgetV2)}
            >
                {({ values, errors, touched, status,
                    setFieldValue, handleBlur, handleChange, handleSubmit }) =>
                (
                    <Form onSubmit={handleSubmit}>
                        <SubmitValidationError callback={this.onSubmitValidationError} />
                        <Grid columns={16}>
                            <Grid.Column width={16}>
                                <PersonalDetailsFormGroup>
                                    <Paragraph1
                                        color="#707070"
                                        dangerouslySetInnerHTML={{
                                            __html: content.ownerDetailsMessage.body,
                                        }}
                                    ></Paragraph1>
                                    <Form.Group widths="equal">
                                        <KpFormField>
                                            <label>SELECT AN OWNERSHIP TYPE</label>
                                            <KpFormDropdown
                                                fluid
                                                selection
                                                backgroundColor={theme.brand.colors.white}
                                                icon="chevron down"
                                                options={[
                                                    {
                                                        key: "Individual",
                                                        text: "Individual",
                                                        value: "Individual",
                                                    },
                                                    {
                                                        key: "Company",
                                                        text: "Registered Company",
                                                        value: "Company",
                                                    },
                                                ]}
                                                value={values.ownershipType}
                                                onChange={(element: any) => {
                                                    setFieldValue(
                                                        "ownershipType",
                                                        element.currentTarget.innerText == "Registered Company" ? "Company" : "Individual"
                                                    );
                                                }} />
                                        </KpFormField>
                                    </Form.Group>
                                    {values.ownershipType === "Company" && (
                                        <>
                                            <Form.Group widths="equal">
                                                <KpFormField
                                                    error={errors.companyDetails?.companyNumber &&
                                                        touched.companyDetails?.companyNumber}
                                                >
                                                    <label>COMPANY NUMBER</label>
                                                    {errors.companyDetails?.companyNumber &&
                                                        touched.companyDetails?.companyNumber && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.companyDetails?.companyNumber}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <input
                                                        id="companyDetails.companyNumber"
                                                        name="companyDetails.companyNumber"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Company Number"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.companyDetails?.companyNumber}
                                                    ></input>
                                                </KpFormField>
                                                <KpFormField
                                                    error={errors.companyDetails?.companyOwner &&
                                                        touched.companyDetails?.companyOwner}
                                                >
                                                    <label>COMPANY NAME</label>
                                                    {errors.companyDetails?.companyOwner &&
                                                        touched.companyDetails?.companyOwner && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.companyDetails?.companyOwner}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <input
                                                        id="companyDetails.companyOwner"
                                                        name="companyDetails.companyOwner"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Company Owner"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.companyDetails?.companyOwner}
                                                    ></input>
                                                </KpFormField>
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                <KpFormField
                                                    error={
                                                        errors.ownerDetails?.email &&
                                                        touched.ownerDetails?.email
                                                    }
                                                >
                                                    <label>OWNER'S EMAIL</label>
                                                    {errors.ownerDetails?.email &&
                                                        touched.ownerDetails?.email && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.ownerDetails?.email}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                        <FormikFieldEmailValidationInput
                                                            id="ownerDetails.email"
                                                            name="ownerDetails.email"
                                                            data-private="lipsum"
                                                            type="text"
                                                            aria-label="Owner Email"
                                                            disabled={status.isSubmitting}
                                                            onChange={handleChange}
                                                            inputValid={this.state.ownerEmailIsValid}
                                                            validate={this.validateOwnerEmailAddress}
                                                            onBlur={handleBlur}
                                                            value={values.ownerDetails?.email}
                                                        />
                                                    <InlineEmailValidatingLoader
                                                        active={this.state.validatingOwnerEmail}
                                                        inline
                                                        size="small"
                                                    />
                                                </KpFormField>
                                                <KpFormField
                                                    error={
                                                        errors.ownerDetails?.phone &&
                                                        touched.ownerDetails?.phone
                                                    }
                                                >
                                                    <label>OWNER'S PHONE</label>
                                                    {errors.ownerDetails?.phone &&
                                                        touched.ownerDetails?.phone && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.ownerDetails?.phone}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <input
                                                        id="ownerDetails.phone"
                                                        name="ownerDetails.phone"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Owner Phone"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.ownerDetails?.phone}
                                                    ></input>
                                                </KpFormField>
                                            </Form.Group>
                                        </>
                                    )}

                                    {values.ownershipType === "Individual" && (
                                        <>
                                            <Form.Group widths="equal">
                                                <KpFormField
                                                    width={5}
                                                    error={errors.ownerDetails?.firstName &&
                                                        touched.ownerDetails?.firstName}
                                                >
                                                    <label>FIRST NAME (Legal Name)</label>
                                                    {errors.ownerDetails?.firstName &&
                                                        touched.ownerDetails?.firstName && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.ownerDetails.firstName}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <input
                                                        id="ownerDetails.firstName"
                                                        name="ownerDetails.firstName"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Owner First Name"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.ownerDetails.firstName}
                                                    ></input>
                                                </KpFormField>
                                                <KpFormField
                                                    width={8}
                                                    error={errors.ownerDetails?.lastName &&
                                                        touched.ownerDetails?.lastName}
                                                >
                                                    <label>LAST NAME (Legal Name)</label>
                                                    <NameNoticeStyle>AS PER DRIVERS LICENSE</NameNoticeStyle>
                                                    {errors.ownerDetails?.lastName &&
                                                        touched.ownerDetails?.lastName && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.ownerDetails.lastName}
                                                            </KpFormInputErrorMessage>
                                                        )}

                                                    <input
                                                        id="ownerDetails.lastName"
                                                        name="ownerDetails.lastName"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Owner Last Name"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.ownerDetails.lastName}
                                                    ></input>
                                                </KpFormField>
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                <KpFormField
                                                    error={
                                                        errors.ownerDetails?.email &&
                                                        touched.ownerDetails?.email
                                                    }
                                                >
                                                    <label>EMAIL</label>
                                                    {errors.ownerDetails?.email &&
                                                        touched.ownerDetails?.email && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.ownerDetails?.email}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                        <FormikFieldEmailValidationInput
                                                            id="ownerDetails.email"
                                                            name="ownerDetails.email"
                                                            data-private="lipsum"
                                                            type="text"
                                                            aria-label="Owner Email"
                                                            disabled={status.isSubmitting}
                                                            onChange={handleChange}
                                                            inputValid={this.state.ownerEmailIsValid}
                                                            validate={this.validateOwnerEmailAddress}
                                                            onBlur={handleBlur}
                                                            value={values.ownerDetails?.email}
                                                        />
                                                    <InlineEmailValidatingLoader
                                                        active={this.state.validatingOwnerEmail}
                                                        inline
                                                        size="small"
                                                    />
                                                </KpFormField>
                                                <KpFormField
                                                    error={
                                                        errors.ownerDetails?.phone &&
                                                        touched.ownerDetails?.phone
                                                    }
                                                >
                                                    <label>PHONE</label>
                                                    {errors.ownerDetails?.phone &&
                                                        touched.ownerDetails?.phone && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.ownerDetails?.phone}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <input
                                                        id="ownerDetails.phone"
                                                        name="ownerDetails.phone"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Owner Phone"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.ownerDetails?.phone}
                                                    ></input>
                                                </KpFormField>
                                            </Form.Group>
                                            {this.props.checkout.manufactureNow && <Form.Group widths="equal">
                                                <KpFormField
                                                    error={
                                                        errors.vehicleDetails?.driverLicense &&
                                                        touched.vehicleDetails?.driverLicense
                                                    }
                                                >
                                                    <label>DRIVER LICENSE NUMBER</label>
                                                    {errors.vehicleDetails?.driverLicense &&
                                                        touched.vehicleDetails?.driverLicense && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.vehicleDetails?.driverLicense}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <InputCapStyle
                                                        id="vehicleDetails.driverLicense"
                                                        name="vehicleDetails.driverLicense"
                                                        data-private="lipsum"
                                                        type="text"
                                                        aria-label="Owner Driver License"
                                                        disabled={status.isSubmitting}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.vehicleDetails?.driverLicense.toUpperCase()}
                                                        placeholder="DZ123456"
                                                    ></InputCapStyle>
                                                </KpFormField>
                                                <FieldBetweenStyle>OR</FieldBetweenStyle>
                                                <KpFormField
                                                    error={
                                                        errors.vehicleDetails?.dob &&
                                                        touched.vehicleDetails?.dob
                                                    }
                                                >
                                                    <label>OWNER DATE OF BIRTH</label>
                                                    {errors.vehicleDetails?.dob &&
                                                        touched.vehicleDetails?.dob && (
                                                            <KpFormInputErrorMessage>
                                                                {errors.vehicleDetails?.dob}
                                                            </KpFormInputErrorMessage>
                                                        )}
                                                    <PersonalDetailsDOBContainer>
                                                        <KpFormDropdown
                                                            placeholder="Day"
                                                            fluid
                                                            selection
                                                            search
                                                            options={Days(
                                                                moment(
                                                                    new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                                )
                                                            )}
                                                            icon="chevron down"
                                                            id="vehicleDetails.dobDay"
                                                            name="vehicleDetails.dobDay"
                                                            disabled={status.isSubmitting}
                                                            value={moment(
                                                                new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                            ).format("D")}
                                                            onChange={(el: any, data: any) => {
                                                                let currentDate = moment(
                                                                    new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                                )
                                                                const day = parseInt(data.value)
                                                                const newDate = currentDate.date(day)
                                                                setFieldValue(
                                                                    "vehicleDetails.dob",
                                                                    newDate.toDate()
                                                                )
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <KpFormDropdown
                                                            placeholder="Month"
                                                            fluid
                                                            selection
                                                            search
                                                            options={Months}
                                                            icon="chevron down"
                                                            id="vehicleDetails.dobMonth"
                                                            name="vehicleDetails.dobMonth"
                                                            disabled={status.isSubmitting}
                                                            value={moment(
                                                                new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                            ).format("M")}
                                                            onChange={(el: any, data: any) => {
                                                                let currentDate = moment(
                                                                    new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                                )
                                                                const month = parseInt(data.value) - 1
                                                                const newDate = currentDate.month(month)
                                                                setFieldValue(
                                                                    "vehicleDetails.dob",
                                                                    newDate.toDate()
                                                                )
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <KpFormDropdown
                                                            placeholder="Year"
                                                            fluid
                                                            selection
                                                            search
                                                            options={Years()}
                                                            icon="chevron down"
                                                            id="vehicleDetails.dobYear"
                                                            name="vehicleDetails.dobYear"
                                                            disabled={status.isSubmitting}
                                                            value={moment(
                                                                new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                            )
                                                                .year()
                                                                .toString()}
                                                            onChange={(el: any, data: any) => {
                                                                let currentDate = moment(
                                                                    new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                                                )
                                                                const year = parseInt(data.value)
                                                                const newDate = currentDate.year(year)
                                                                setFieldValue(
                                                                    "vehicleDetails.dob",
                                                                    newDate.toDate()
                                                                )
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                    </PersonalDetailsDOBContainer>

                                                </KpFormField>
                                            </Form.Group>}
                                        </>
                                    )}

                                    {this.props.checkout.manufactureNow && (
                                        <>
                                            <AddressFinderFormGroup
                                                show={!this.props.checkout.ownerAddress
                                                    .isManualAddress}
                                                widths="equal"
                                            >
                                                <KpFormField
                                                    error={errors.ownerAddress?.street &&
                                                        touched.ownerAddress?.fullAddress}
                                                >
                                                    <AddressSearchTitleSection>
                                                        <label>OWNER ADDRESS</label>
                                                        <AddressToggerLink
                                                            onClick={() => this.toggleManualAddress(setFieldValue)}
                                                        >
                                                            ENTER ADDRESS MANUALLY
                                                        </AddressToggerLink>
                                                        {this.props.checkout.manufactureNow &&
                                                            errors.ownerAddress?.street &&
                                                            touched.ownerAddress?.fullAddress && (
                                                                <KpFormInputErrorMessage>
                                                                    Please select an address
                                                                </KpFormInputErrorMessage>
                                                            )}
                                                    </AddressSearchTitleSection>

                                                    <AddressSearchAutoComplete
                                                        id="ownerAddress.fullAddress"
                                                        autoComplete="new-password"
                                                        loading={this.state.searchingAddress}
                                                        name="ownerAddress.fullAddress"
                                                        type="text"
                                                        aria-label="Full Addr"
                                                        disabled={status.isSubmitting}
                                                        minCharacters={2}
                                                        onSearchChange={(
                                                            event: SyntheticEvent,
                                                            data: any
                                                        ) => {
                                                            setFieldValue(
                                                                "ownerAddress.fullAddress",
                                                                data.value
                                                            );
                                                            setFieldValue("ownerAddress.unit", "");
                                                            setFieldValue("ownerAddress.city", "");
                                                            setFieldValue("ownerAddress.postCode", "");
                                                            setFieldValue("ownerAddress.street", "");
                                                            setFieldValue("ownerAddress.suburb", "");
                                                            this.handleAddressSearchChange(event, data);
                                                        }}
                                                        onResultSelect={(
                                                            event: SyntheticEvent,
                                                            data: any
                                                        ) => {
                                                            this.setState({
                                                                addressValue: data.result.title,
                                                                searchingAddress: true,
                                                            });
                                                            setFieldValue(
                                                                "ownerAddress.fullAddress",
                                                                data.result.title
                                                            );
                                                            const token = this.props.addressValidationToken;
                                                            AddressFinderService.experianAddressMetadata(
                                                                token,
                                                                data.result.id
                                                            ).then((result: any) => {
                                                                const unit = result.address_line_2
                                                                    ? result.address_line_1
                                                                    : "";
                                                                const addressLine1 = result.address_line_2
                                                                    ? result.address_line_2
                                                                    : result.address_line_1;

                                                                setFieldValue("ownerAddress.unit", unit);
                                                                setFieldValue(
                                                                    "ownerAddress.city",
                                                                    result.region
                                                                );
                                                                setFieldValue(
                                                                    "ownerAddress.postCode",
                                                                    result.postal_code
                                                                );
                                                                setFieldValue(
                                                                    "ownerAddress.street",
                                                                    addressLine1
                                                                );
                                                                setFieldValue(
                                                                    "ownerAddress.suburb",
                                                                    result.locality
                                                                );
                                                                this.setState({
                                                                    addressValue: data.result.title,
                                                                    searchingAddress: false,
                                                                });
                                                            });
                                                        }}
                                                        showNoResults={false}
                                                        value={this.state.addressValue}
                                                        results={this.state.addressResults}
                                                        resultRenderer={addressResultRender} />
                                                </KpFormField>
                                            </AddressFinderFormGroup>

                                            {this.props.checkout.ownerAddress
                                                .isManualAddress && (
                                                    <PersonalDetailsManualAddressContainer>
                                                        <Grid>
                                                            <Grid.Column textAlign="left" width={8}>
                                                                <PersonalDetailsManualAddressContainerHeading>
                                                                    Manual Address Input
                                                                </PersonalDetailsManualAddressContainerHeading>
                                                                <Legal color={theme.brand.colors.blue}>
                                                                    {" "}
                                                                    (physical address is REQUIRED)
                                                                </Legal>
                                                            </Grid.Column>
                                                            <Grid.Column textAlign="right" width={8}>
                                                                <PersonalDetailsContainerLink
                                                                    onClick={() => this.toggleManualAddress(setFieldValue)}
                                                                >
                                                                    USE ADDRESS LOOKUP
                                                                </PersonalDetailsContainerLink>
                                                            </Grid.Column>
                                                        </Grid>
                                                        <Form.Group widths="equal">
                                                            <KpFormField
                                                                error={errors.ownerAddress?.building &&
                                                                    touched.ownerAddress?.building}
                                                            >
                                                                <label>BUILDING NAME</label>
                                                                {errors.ownerAddress?.building &&
                                                                    touched.ownerAddress?.building && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.ownerAddress?.building}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="ownerAddress.building"
                                                                    name="ownerAddress.building"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Owner Address - building"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ownerAddress?.building}
                                                                ></input>
                                                            </KpFormField>
                                                            <KpFormField
                                                                error={errors.ownerAddress?.unit &&
                                                                    touched.ownerAddress?.unit}
                                                            >
                                                                <label>UNIT #, FLOOR, LEVEL</label>
                                                                {errors.ownerAddress?.unit &&
                                                                    touched.ownerAddress?.unit && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.ownerAddress?.unit}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="ownerAddress.unit"
                                                                    name="ownerAddress.unit"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Owner Address - unit"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ownerAddress?.unit}
                                                                ></input>
                                                            </KpFormField>
                                                        </Form.Group>
                                                        <Form.Group widths="equal">
                                                            <KpFormField
                                                                error={errors.ownerAddress?.street &&
                                                                    touched.ownerAddress?.street}
                                                            >
                                                                <label>STREET ADDRESS</label>
                                                                {errors.ownerAddress?.street &&
                                                                    touched.ownerAddress?.street && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.ownerAddress?.street}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="ownerAddress.street"
                                                                    name="ownerAddress.street"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Owner Address - street"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ownerAddress?.street}
                                                                ></input>
                                                            </KpFormField>
                                                            <KpFormField
                                                                error={errors.ownerAddress?.suburb &&
                                                                    touched.ownerAddress?.suburb}
                                                            >
                                                                <label>SUBURB</label>
                                                                {errors.ownerAddress?.suburb &&
                                                                    touched.ownerAddress?.suburb && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.ownerAddress?.suburb}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="ownerAddress.suburb"
                                                                    name="ownerAddress.suburb"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Owner Address - suburb"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ownerAddress?.suburb}
                                                                ></input>
                                                            </KpFormField>
                                                        </Form.Group>
                                                        <Form.Group widths="equal">
                                                            <KpFormField
                                                                error={errors.ownerAddress?.city &&
                                                                    touched.ownerAddress?.city}
                                                            >
                                                                <label>CITY OR TOWN</label>
                                                                {errors.ownerAddress?.city &&
                                                                    touched.ownerAddress?.city && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.ownerAddress?.city}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="ownerAddress.city"
                                                                    name="ownerAddress.city"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Owner Address - city"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ownerAddress?.city}
                                                                ></input>
                                                            </KpFormField>
                                                            <KpFormField
                                                                error={errors.ownerAddress?.postCode &&
                                                                    touched.ownerAddress?.postCode}
                                                            >
                                                                <label>POSTCODE</label>
                                                                {errors.ownerAddress?.postCode &&
                                                                    touched.ownerAddress?.postCode && (
                                                                        <KpFormInputErrorMessage>
                                                                            {errors.ownerAddress?.postCode}
                                                                        </KpFormInputErrorMessage>
                                                                    )}
                                                                <input
                                                                    id="ownerAddress.postCode"
                                                                    name="ownerAddress.postCode"
                                                                    data-private="lipsum"
                                                                    type="text"
                                                                    aria-label="Owner Address - postcode"
                                                                    disabled={status.isSubmitting}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.ownerAddress?.postCode}
                                                                ></input>
                                                            </KpFormField>
                                                        </Form.Group>
                                                    </PersonalDetailsManualAddressContainer>
                                                )}
                                        </>
                                    )}

                                    {this.props.checkout.manufactureNow && (<>
                                        <Form.Group widths="equal">
                                            <KpFormField
                                                error={
                                                    errors.vehicleDetails?.vehicleMake &&
                                                    touched.vehicleDetails?.vehicleMake
                                                  }
                                            >
                                                <label>VEHICLE MAKE</label>
                                                {errors.vehicleDetails?.vehicleMake &&
                                                touched.vehicleDetails?.vehicleMake && (
                                                    <KpFormInputErrorMessage>
                                                    {errors.vehicleDetails?.vehicleMake}
                                                    </KpFormInputErrorMessage>
                                                )}
                                                <InputCapStyle
                                                    id="vehicleDetails.vehicleMake"
                                                    name="vehicleDetails.vehicleMake"
                                                    data-private="lipsum"
                                                    type="text"
                                                    aria-label="Vehicle Make"
                                                    disabled={status.isSubmitting}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.vehicleDetails?.vehicleMake.toUpperCase()}
                                                ></InputCapStyle>
                                            </KpFormField>
                                            <KpFormField
                                                error={
                                                    errors.vehicleDetails?.vehicleModel &&
                                                    touched.vehicleDetails?.vehicleModel
                                                  }
                                            >
                                                <label>VEHICLE MODEL</label>
                                                {errors.vehicleDetails?.vehicleModel &&
                                                touched.vehicleDetails?.vehicleModel && (
                                                    <KpFormInputErrorMessage>
                                                    {errors.vehicleDetails?.vehicleModel}
                                                    </KpFormInputErrorMessage>
                                                )}
                                                <InputCapStyle
                                                    id="vehicleDetails.vehicleModel"
                                                    name="vehicleDetails.vehicleModel"
                                                    type="text"
                                                    data-private="lipsum"
                                                    aria-label="Vehicle Model"
                                                    disabled={status.isSubmitting}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.vehicleDetails?.vehicleModel.toUpperCase()}
                                                ></InputCapStyle>
                                            </KpFormField>
                                        </Form.Group>
                                        <PlateOptionsWrapper>
                                            <Form.Group widths="equal">
                                                <Responsive as={Grid} minWidth={theme.responsive.desktop.minWidth}>
                                                    <KpFormField>
                                                        <TabWrapper>
                                                            <TabStyle onClick={() => this.ChangeVehicleRegistration(true)} className={this.state.showExistingPlate ? "active" : ""}>Existing Registration</TabStyle>
                                                            <TabStyle onClick={() => this.ChangeVehicleRegistration(false)} className={this.state.showNewPlate ? "active" : ""}>No Current Registration</TabStyle>
                                                        </TabWrapper>
                                                    </KpFormField>
                                                </Responsive>
                                                <Responsive as={Grid.Column} maxWidth={theme.responsive.desktop.minWidth} style={{width:"100%;"}}>
                                                    <PaymentOptionRadioButton fluid
                                                    onClick={() =>this.ChangeVehicleRegistration(true)}
                                                    active={this.state.showExistingPlate ? true : false}>
                                                        Existing Registration
                                                        <ExpendIndicatorIcon name={this.state.showExistingPlate ? "minus" : "plus"}/>
                                                    </PaymentOptionRadioButton>
                                                    <TabContentOptionStyle active={this.state.showExistingPlate ? true : false} >
                                                        <label>EXISTING PLATE NUMBER</label>
                                                        <InputCapStyle
                                                            id="vehicleDetails.existingCombination"
                                                            name="vehicleDetails.existingCombination"
                                                            data-private="lipsum"
                                                            type="text"
                                                            aria-label="Owner Vehicle Existing Combination"
                                                            maxLength={6}
                                                            disabled={status.isSubmitting || disableEditExistingPlate}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={
                                                                values.vehicleDetails?.existingCombination.toUpperCase()
                                                            }
                                                        ></InputCapStyle>
                                                    </TabContentOptionStyle>
                                                    <PaymentOptionRadioButton fluid
                                                    onClick={() =>this.ChangeVehicleRegistration(false)}
                                                    active={this.state.showNewPlate ? true : false}>
                                                        No Current Registration
                                                        <ExpendIndicatorIcon name={this.state.showNewPlate ? "minus" : "plus"} />
                                                    </PaymentOptionRadioButton>
                                                </Responsive>
                                            </Form.Group>
                                            <Form.Group widths="equal">
                                                {this.state.showExistingPlate && <KpFormField>
                                                    <Responsive minWidth={theme.responsive.desktop.minWidth}>
                                                        <TabContentWrap>
                                                            <label>EXISTING PLATE NUMBER</label>
                                                            <InputCapStyle
                                                                id="vehicleDetails.existingCombination"
                                                                name="vehicleDetails.existingCombination"
                                                                data-private="lipsum"
                                                                type="text"
                                                                aria-label="Owner Vehicle Existing Combination"
                                                                maxLength={6}
                                                                disabled={status.isSubmitting || disableEditExistingPlate}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={
                                                                    values.vehicleDetails?.existingCombination.toUpperCase()
                                                                }
                                                            ></InputCapStyle>
                                                        </TabContentWrap>
                                                    </Responsive>
                                                </KpFormField>}
                                                {!this.state.showExistingPlate &&
                                                <TabContentWrap>
                                                    <Form.Field>
                                                        <label>UPLOAD VOSA</label>
                                                        <br />
                                                        <SubTitleStyle>A VOSA signed by the customer is only required if the vehicle is not yet registered</SubTitleStyle>
                                                        <FileUploadBigWrapper>
                                                            <List divided relaxed>
                                                                {this.state.uploadingImages.map(img => {
                                                                    return (
                                                                        <ListItemStyle>
                                                                            {
                                                                                img.fileType == 'image/png' || img.fileType == 'img/gif' || img.fileType == "image/jpeg" ?
                                                                                    <List.Icon name='file image' />
                                                                                    :
                                                                                    <List.Icon name='file pdf' />
                                                                            }
                                                                            <List.Content>
                                                                                <List.Header>{img.name}
                                                                                <UploadResultStyle>
                                                                                {
                                                                                    img.uploaded && img.uploaded == true ?
                                                                                        <a onClick={() => this.deleteFile(img.id)}>Delete</a>
                                                                                        :
                                                                                        <List.Description>Uploading...</List.Description>
                                                                                }
                                                                                </UploadResultStyle>
                                                                                </List.Header>
                                                                            </List.Content>
                                                                        </ListItemStyle>
                                                                    )
                                                                })}
                                                            </List>
                                                            {this.state.uploadFileError &&
                                                                <UploadFileErrorStyle>{this.state.uploadFileError}</UploadFileErrorStyle>
                                                            }
                                                            <ImageUploadWrapper>
                                                                <DragTextInput>
                                                                    <Icon name="cloud upload" size="huge" color="green" />
                                                                    <TextInputText>
                                                                        Upload your VOSA File
                                                                    </TextInputText>
                                                                    <TextInputSub>File must be one of the following file types (PDF, JPG, PNG) and no larger than 2MB</TextInputSub>
                                                                </DragTextInput>
                                                                <InputWrapper>
                                                                    <FileUploadInput
                                                                        type="file"
                                                                        id="vehicleDetails.vosa"
                                                                        name="vehicleDetails.vosa"
                                                                        accept="image/png, image/gif, image/jpeg, application/pdf"
                                                                        multiple={true}
                                                                        onChange={this.upload}
                                                                        onClick={(event)=> {
                                                                            var element = event.target as HTMLInputElement
                                                                            element.value = ""
                                                                    }}
                                                                    />
                                                                    <ButtonText>BROWSER FILE</ButtonText>
                                                                </InputWrapper>
                                                            </ImageUploadWrapper>
                                                        </FileUploadBigWrapper>
                                                        <SubTitleStyle>*VOSA (Vehicle Sales Agreement) must be signed and dated by the customer and display the VIN number</SubTitleStyle>
                                                    </Form.Field>
                                                </TabContentWrap>}
                                            </Form.Group>
                                        </PlateOptionsWrapper>
                                    </>)}
                                </PersonalDetailsFormGroup>
                            </Grid.Column>
                        </Grid>
                        {this.state.reCaptchaError && <KpFormErrorMessageLeft>{this.state.reCaptchaError}</KpFormErrorMessageLeft>}
                        <div className="g-recaptcha-v2" data-sitekey={this.props.reCaptchaSiteKeyV2} id="g-recaptcha_dealerDetails-v2" style={{display:"none"}}></div>
                        <PersonalDetailsButtonContainer verticalAlign="bottom">
                            <Grid.Column width={16}>
                                <Grid reversed="mobile vertically">
                                    <Grid.Column mobile={16} tablet={8} computer={8}>
                                        <KpButton
                                            id="pdf-back"
                                            fullWidth="mobile"
                                            buttonType="secondary"
                                            color={theme.brand.colors.darkGrey}
                                            link={this.props.checkout.isAcceptStatement ? "plate-manufacture-selection" : "dealer-statement"}
                                        >
                                            BACK
                                        </KpButton>
                                    </Grid.Column>
                                    <Grid.Column
                                        mobile={16}
                                        tablet={8}
                                        computer={8}
                                        textAlign="right"
                                    >
                                        <KpButton
                                            id="pdf-next"
                                            fullWidth="mobile"
                                            disabled={this.state.searchingAddress}
                                            loading={status.isSubmitting}
                                            buttonType="primary"
                                            color={theme.brand.colors.blue}
                                            type="submit"
                                        >
                                            CONTINUE
                                        </KpButton>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                        </PersonalDetailsButtonContainer>
                    </Form>
                )
                }
            </Formik>
            )}</ReCaptcha>
        </PersonalDetailsFormContainer>)
    }
}

export default OwnerDetailsForm
