import React, { SyntheticEvent } from "react"
import { H2, Paragraph1, H5, Legal } from "@styles/Global.styles"
import { Field, Formik } from "formik"
import { Checkbox, Form, Grid, Input, Loader } from "semantic-ui-react"
import theme from "@styles/theme"
import {
  KpFormField,
  KpFormInputErrorMessage,
  KpFormDropdown,
  KpFormErrorMessageLeft,
} from "@styles/Global.forms"
import {
  PersonalDetailsManualAddressContainer,
  PersonalDetailsContainerLink,
  PersonalDetailsManualAddressContainerHeading,
  PersonalDetailsFormContainer,
  PersonalDetailsButtonContainer,
  PersonalDetailsFormGroup,
  AddressFinderResultItem,
  AddressSearchTitleSection,
  AddressSearchAutoComplete,
  AddressFinderFormGroup,
  AddressToggerLink,
  PersonalDetailsDOBContainer,
  PersonalDetailsTitleDropdown,
  FormikFieldEmailValidationInput,
  InlineEmailValidatingLoader,
  ExpressCheckoutToggleContainer,
  InputCapStyle,
} from "./PersonalDetailsForm.styles"
import { personalDetailsValidationSchema } from "./PersonalDetailsForms.validationSchema"
import { KpButton } from "@elements/index"
import { Checkout, Address } from "@models/Checkout/Checkout"
import { navigate } from "gatsby"
import { PersonalDetailsFormContentModel } from "@models/PersonalDetailsForm/PersonalDetailsFormContentModel"
import moment from "moment"
import { AddressFinderService } from "@services/AddressFinderService"
import _ from "lodash"
import { connect as formikConnect } from "formik"
import { BasketResponseModel } from "@models/Basket/BasketResponseModel"
import { setBasketAttribute, setBasketAttributes } from "@services/SalesRecoveryService"
import { Months, Days, Years } from "@utils/DateDropdownValues"
import { EmailValidationService } from "@services/EmailValidationService"
import ReCaptcha from "@utils/reCaptcha"
import { ReCaptchaService } from "@services/ReCaptchaService"
import { PersonalDetailsSection, ReCaptchaThreshold } from "@utils/Constant"
import { TransactionTypesSetting } from "@models/ProductModels"
import { CheckoutSelectionCardModel } from "@models/Checkout/CheckoutSelectionCard"
import { CheckoutSelectionCard } from "@components/CheckoutSelectionCard"
import { AddressToggerLinkDiv } from "@components/PersonalDetailsForm/PersonalDetailsForm.styles"
import { GiftingType } from "../GiftingSelection/GiftingType"

interface PersonalDetailsProps {
  apiUrl: string
  emailValidationToken: string
  addressValidationToken: string
  reCaptchaSiteKey: string
  reCaptchaSiteKeyV2: string
  checkoutState: Checkout
  basketState: BasketResponseModel
  content: PersonalDetailsFormContentModel
  updateManufactureNow: (manufactureNow: boolean) => void
  updatePersonalDetails: (personalDetails: Checkout) => void
  updateOwnerAddress: (ownerAddress: Address) => void
  updateDeliveryAddress: (deliveryAddress: Address) => void
  section: string
}

interface PersonalDetailsState {
  ownershipType: string
  manualAddress: boolean
  searchingAddress: boolean
  addressResults: Array<any>
  addressValue: string
  validatingOwnerEmail: boolean
  validatingApplicantEmail: boolean
  currentOwnerEmail: string
  currentApplicantEmail: string
  ownerEmailIsValid?: boolean
  applicantEmailIsValid?: boolean
  reCaptchaToken?: string
  reCaptchaError: string,
  individual: boolean,
  business: boolean,
  detailsButton: boolean,
  addressButton: boolean,
  plateRegistrationButton: boolean
}

function OnSubmitValidationError(props: any) {
  const { callback, formik } = props

  const effect = () => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      callback(formik)
    }
  }
  React.useEffect(effect, [formik.submitCount, formik.isSubmitting])

  return null
}

export const SubmitValidationError = formikConnect(OnSubmitValidationError)

export const addressResultRender = ({ title }: { title: string }) => (
  <AddressFinderResultItem>{title}</AddressFinderResultItem>
)

/* eslint-disable jsx-a11y/label-has-associated-control */

class MobilePersonalDetailsForm extends React.Component<
  PersonalDetailsProps,
  PersonalDetailsState
  > {
  debouncedFn: any

  constructor(props: PersonalDetailsProps) {
    super(props)
    this.state = {
      manualAddress: false,
      ownershipType: "",
      searchingAddress: false,
      addressResults: [],
      validatingOwnerEmail: false,
      validatingApplicantEmail: false,
      currentApplicantEmail: this.props.checkoutState.applicantDetails.email,
      currentOwnerEmail: this.props.checkoutState.ownerDetails.email,
      addressValue: this.props.checkoutState.ownerAddress.fullAddress,
      reCaptchaToken: undefined,
      reCaptchaError: "",
      individual: false,
      business: false,
      detailsButton: false,
      addressButton: false,
      plateRegistrationButton: false
    }
  }

  intialStatus = {
    isSubmitting: false,
    isSubmitted: false,
  }

  updatePhoneNumber = (phone: string) => {
    if (!phone.startsWith('+64')){
      if (phone.startsWith('0064')){
        phone = phone.replace('0064', '+64')
      }
      if(phone.startsWith('0')){
        phone = phone.replace('0', '+64 ')
      }
      if(!phone.startsWith('+64')) {
        phone = "+64 ".concat(phone);
      }
    }
    if (!phone.startsWith('+64 ')){
        phone = phone.replace('+64', '+64 ')
    }

    return phone
  }

  isObjectHasEmptyAttributes(p: any) {
    for (var key of Object.keys(p)) {
      if(key != "title"){
        if(p[key] === "" || p[key] === undefined || p[key] === null){
          return true
        }
      }
    }
    return false
  }

  isAddressHasEmptyAttributes(p: any) {
    if(p.isManualAddress){
      for (var key of Object.keys(p)) {
        if(key != "fullAddress" && key != 'building' && key != 'unit' && key != 'suburb'){
          if(p[key] === "" || p[key] === undefined || p[key] === null){
            return true
          }
        }
      }
    } else {
      for (var key of Object.keys(p)) {
        if(key === "fullAddress" || key === 'postCode' || key === 'street' || key === 'city'){
          if(p[key] === "" || p[key] === undefined || p[key] === null){
            return true
          }
        }
      }
    }
    return false
  }

  submitForm = async (values: any, actions: any, execute: () => Promise<string>, widgetV2: number | undefined) => {
    if (
      !this.state.searchingAddress &&
      !this.state.validatingOwnerEmail &&
      !this.state.validatingApplicantEmail
    ) {
      actions.setStatus({
        isSubmitting: true,
        isSubmitted: false,
      })

      let recaptcha_v2: HTMLElement | null
      if(document){
        recaptcha_v2 = document.getElementById("g-recaptcha_personalDetails-v2")
      } else {
        return
      }

      if(recaptcha_v2 && recaptcha_v2.style.display == "block"){
        const token = window.grecaptcha.getResponse(widgetV2)
        ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v2").then(async (response: any) => {
          if (response && response.data.Success)
          {
            this.setState({reCaptchaError: ""})
            this.processSubmit(values)
          } else {
            actions.setStatus({
              isSubmitting: false,
              isSubmitted: false
            })
            this.setState({reCaptchaError: "Please tick checkbox correctly"})
            return false
          }
        })

      } else {
        const token = await execute()
        this.setState({ reCaptchaToken: token })

        ReCaptchaService.validateCaptcha(this.props.apiUrl, token, "v3").then(async (response: any) => {
          if (response.status != 200) return 'recaptcha validate error'

          if (response.data.Data && (!response.data.Data.success || response.data.Data.score < ReCaptchaThreshold)) {
            actions.setStatus({
              isSubmitting: false,
              isSubmitted: false
            })
            if (document && recaptcha_v2){
              recaptcha_v2!.style.display = "block"
            }
            return false
          } else {
            this.processSubmit(values)
          }
        })
      }
    } else {
      actions.setSubmitting(false)
    }
  }

  processSubmit = (values: any) => {
    if (values.ownerDetails?.phone){
      values.ownerDetails.phone = this.updatePhoneNumber(values.ownerDetails?.phone)
    }
    if (values.applicantDetails?.phone){
      values.applicantDetails.phone = this.updatePhoneNumber(values.applicantDetails?.phone)
    }

    this.props.updatePersonalDetails(values)

    const payload = this.createPayload(values)
    if (this.props.basketState.basketId) {
      setBasketAttributes(
        this.props.apiUrl,
        this.props.basketState.basketId,
        payload
      )
    }

    //Manufacture later in mobile, owner details has been removed
    //Setting up owner = applicant
    // if(!values.manufactureNow) {
    //   values.ownerDetails.firstName = values.ownerDetails.firstName === "" ?  values.applicantDetails.firstName : values.ownerDetails.firstName
    //   values.ownerDetails.lastName = values.ownerDetails.lastName === "" ?  values.applicantDetails.lastName : values.ownerDetails.lastName
    //   values.ownerDetails.email = values.ownerDetails.email === "" ?  values.applicantDetails.email : values.ownerDetails.email
    //   values.ownerDetails.phone = values.ownerDetails.phone === "" ?  values.applicantDetails.phone : values.ownerDetails.phone
    // }

    const checkoutState = this.props.checkoutState
    if (
      checkoutState.isLayby ||
      !checkoutState.ownerIsApplicant ||
      checkoutState.isMultiPlate ||
      !checkoutState.manufactureNow
    ) {
      navigate("/review-payment/")
    } else {
      navigate("/delivery-address-selection/")
    }
  }

  createPayload = (values: any) => {
    let payload =  [
      { AttributeName: "promoSubscribed",
        Value: values.promoSubscribed == true ? "true" : "false"
      },{
        AttributeName: "Checkout.Owner.FirstName",
        Value: values.ownerDetails?.firstName,
      },{
        AttributeName: "Checkout.Owner.LastName",
        Value: values.ownerDetails?.lastName,
      },{
        AttributeName: "Checkout.Owner.Title",
        Value: values.ownerDetails?.title,
      },{
        AttributeName: "Checkout.Owner.Phone",
        Value: values.ownerDetails?.phone
      },{
        AttributeName: "Checkout.Owner.Email",
        Value: values.ownerDetails?.email
      }]

      if (values.manufactureNow){
        payload.push({
          AttributeName: "Checkout.Owner.Address3",
          Value: values.ownerAddress?.street
        },{
          AttributeName: "Checkout.Owner.City",
          Value: values.ownerAddress?.city
        },{
          AttributeName: "Checkout.Owner.PostCode",
          Value: values.ownerAddress?.postCode
        },{
          AttributeName: "Checkout.Owner.PlatesOwned",
          Value: values.vehicleDetails?.existingCombination
        },{
          AttributeName: "Checkout.Delivery.VehicleMake",
          Value: values.vehicleDetails?.vehicleMake
        },{
          AttributeName: "Checkout.Delivery.VehicleModel",
          Value: values.vehicleDetails?.vehicleModel
        },{
          AttributeName: "Checkout.Delivery.LicenceNo",
          Value: values.vehicleDetails?.driverLicense
        },{
          AttributeName: "Checkout.Delivery.ExistingPlate",
          Value: values.vehicleDetails?.existingCombination
        },{
          AttributeName: "Checkout.Owner.BirthDate",
          Value: values.vehicleDetails?.dob
        })

        if(values.ownerAddress.building && values.ownerAddress.building.trim()){
          payload.push({
            AttributeName: "Checkout.Owner.Address1",
            Value: values.ownerAddress?.building
          })
        }
        if(values.ownerAddress.unit && values.ownerAddress.unit.trim()){
          payload.push({
            AttributeName: "Checkout.Owner.Address2",
            Value: values.ownerAddress?.unit
          })
        }
        if(values.ownerAddress.fullAddress && values.ownerAddress.fullAddress.trim()){
          payload.push({
            AttributeName: "Checkout.Owner.StreetAddressFull",
            Value: values.ownerAddress?.fullAddress
          })
        }
      }

      if (values.ownershipType.toLowerCase() == "company"){
        payload.push({
          AttributeName: "Checkout.Owner.CompanyName",
          Value: values.companyDetails?.companyOwner
        })
      }

      if (!values.applicantDetails){
        payload.push({
          AttributeName: "Checkout.Applicant.Title",
          Value: values.applicantDetails?.title,
        },{
          AttributeName: "Checkout.Applicant.FirstName",
          Value: values.applicantDetails?.firstName,
        },{
          AttributeName: "Checkout.Applicant.LastName",
          Value: values.applicantDetails?.lastName,
        },{
          AttributeName: "Checkout.Applicant.Phone",
          Value: values.applicantDetails?.phone
        },{
          AttributeName: "Checkout.Applicant.Email",
          Value: values.applicantDetails?.email
        })
      } else {
        if(values.applicantDetails?.firstName){
          payload.push({
            AttributeName: "Checkout.Applicant.Title",
            Value: values.applicantDetails?.title,
          },{
            AttributeName: "Checkout.Applicant.FirstName",
            Value: values.applicantDetails?.firstName,
          },{
            AttributeName: "Checkout.Applicant.LastName",
            Value: values.applicantDetails?.lastName,
          },{
            AttributeName: "Checkout.Applicant.Phone",
            Value: values.applicantDetails?.phone
          },{
            AttributeName: "Checkout.Applicant.Email",
            Value: values.applicantDetails?.email
          })
        }
      }

      if (values.isGifting){
        payload.push({
          AttributeName: 'Checkout.GiftingDetails.GiftingType',
          Value: values.giftingType
        },{
          AttributeName: 'Checkout.GiftingDetails.To',
          Value: values.giftingDetails?.to
        },{
          AttributeName: 'Checkout.GiftingDetails.From',
          Value: values.giftingDetails?.from
        },{
          AttributeName: 'Checkout.GiftingDetails.Message',
          Value: values.giftingDetails?.message
        },{
          AttributeName: 'Checkout.GiftingDetails.IsForMe',
          Value: values.giftingDetails?.isForMe
        })
      } else {
        payload.push({
          AttributeName: 'Checkout.GiftingDetails.GiftingType',
          Value: 0
        })
      }
      return payload
  }

  handleAddressSearchChange = (e: SyntheticEvent, data: any) => {
    this.setState({ addressValue: data.value })
    if (!this.debouncedFn) {
      this.debouncedFn = _.debounce(this.searchAddress, 500)
    }
    this.debouncedFn()
  }
  searchAddress = () => {
    const input = this.state.addressValue
    this.setState({
      searchingAddress: true,
    })

    const token = this.props.addressValidationToken
    AddressFinderService.experianAddressSearch(token, input).then(
      (result: any) => {
        const matchedAddresses = result.map((item: any) => ({
          title: item.text,
          id: item.global_address_key,
        }))
        this.setState({
          addressResults: matchedAddresses,
          searchingAddress: false,
        })
      }
    )
  }

  toggleManualAddressInput() {
    this.setState({
      manualAddress: !this.state.manualAddress,
    })
  }

  toggleManualAddress = (setFieldValue: any) => {
    const isManualAddress = !this.props.checkoutState.ownerAddress
      .isManualAddress

    setFieldValue("ownerAddress.isManualAddress", isManualAddress)
    setFieldValue("ownerAddress.fullAddress", "")
    setFieldValue("ownerAddress.unit", "")
    setFieldValue("ownerAddress.city", "")
    setFieldValue("ownerAddress.postCode", "")
    setFieldValue("ownerAddress.street", "")
    setFieldValue("ownerAddress.suburb", "")
    this.setState({
      addressValue: "",
    })

    const ownerAddress = this.props.checkoutState.ownerAddress
    ownerAddress.isManualAddress = isManualAddress
    this.props.updateOwnerAddress(ownerAddress)
  }

  toggleManufactureNow = () => {
    this.props.updateManufactureNow(!this.props.checkoutState.manufactureNow)
  }

  changeOwnershipType = (value: any, setFieldValue: any) => {
    setFieldValue("ownershipType", value)
    this.setState({
      ownershipType: value,
      individual: value === "Company" ? false : true,
      business: value != "Company" ? false : true
    }, () => {
      setTimeout(() => {
        var url = "/personal-details/#details"
        navigate(url)
      }, 400);
    })
  }

  validateApplicantEmailAddress = (value: any) => {
    if (this.state.currentApplicantEmail !== value) {
      this.setState({
        currentApplicantEmail: value,
        validatingApplicantEmail: true,
        applicantEmailIsValid: undefined,
      })
      let error
      return EmailValidationService.validate(
        this.props.emailValidationToken,
        value,
        true
      ).then((result: any) => {
        if (result.success == true) {
          setTimeout(() => {
            this.setState({
              validatingApplicantEmail: false,
              applicantEmailIsValid: true,
            }, () => {
            })
          }, 800);
        } else {
          error = "Invalid Email"
          this.setState({
            validatingApplicantEmail: false,
            applicantEmailIsValid: false,
          })
          return error
        }
      })
    } else if (this.state.applicantEmailIsValid == false) {
      return "Invalid Email"
    }
  }
  validateOwnerEmailAddress = (value: any) => {
    if (this.state.currentOwnerEmail !== value) {
      this.setState({
        currentOwnerEmail: value,
        validatingOwnerEmail: true,
        ownerEmailIsValid: undefined,
      })
      let error
      return EmailValidationService.validate(
        this.props.emailValidationToken,
        value,
        true
      ).then((result: any) => {
        if (result.success == true) {
          setTimeout(() => {
            this.setState({
              validatingOwnerEmail: false,
              ownerEmailIsValid: true,
            }, () => {
            })
          }, 800);
        } else {
          error = "Invalid Email"
          this.setState({
            validatingOwnerEmail: false,
            ownerEmailIsValid: false,
          })
          return error
        }
      })
    } else if (this.state.ownerEmailIsValid == false) {
      return "Invalid Email"
    }
  }

  submitBasketAttributeValue = (
    attributeName: string,
    attributeValue: string,
    errors?: any
  ) => {
    if (this.props.basketState.basketId) {
      setBasketAttribute(
        this.props.apiUrl,
        this.props.basketState.basketId,
        attributeName,
        attributeValue
      )
    }
  }

  onSubmitValidationError = (event: any) => {
    console.log("error on validate submission")
    console.log(event)
  }
  render() {
    const { content } = this.props
    var checkout = this.props.checkoutState;
    var disableEditExistingPlate = false;
    if (this.props.basketState.basketLines && this.props.basketState.basketLines.length == 1 && this.props.checkoutState.manufactureNow && this.props.basketState.basketLines[0].transactionTypeId == TransactionTypesSetting.Upgrade.id){
        checkout.vehicleDetails.existingCombination = this.props.basketState.basketLines[0].combination
        disableEditExistingPlate = true;
    }


    var individualCard:CheckoutSelectionCardModel = {
      body: "",
      content: "",
      heading: "Individual",
      items: []
    };

    var businessCard:CheckoutSelectionCardModel = {
      body: "",
      content: "",
      heading: "Business",
      items: []
    };

    return (
      <PersonalDetailsFormContainer>
        <ReCaptcha action="personalDetails" reCaptchaSiteKey={this.props.reCaptchaSiteKey} reCaptchaSiteKeyV2={this.props.reCaptchaSiteKeyV2}>{captcha => (
        <Formik
          initialValues={checkout}
          validationSchema={personalDetailsValidationSchema}
          initialStatus={this.intialStatus}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values: any, actions: any) =>
            this.submitForm(values, actions, captcha.execute, captcha.widgetV2)
          }
        >
          {({
            errors,
            values,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isValidating,
            validateField,
            isValid,
            dirty,
          }) => (
              <Form onSubmit={handleSubmit}>
                <SubmitValidationError callback={this.onSubmitValidationError} />
                <Grid columns={16}>
                  <Grid.Column width={16}>
                    {(values.isMultiPlate || (!values.manufactureNow && this.props.section.includes(PersonalDetailsSection.Details))) &&
                    (<PersonalDetailsFormGroup>
                      {(values.isMultiPlate || !values.ownerIsApplicant) &&
                        <React.Fragment>
                        <H2>Personal Details</H2>
                        <H5>
                          {values.isMultiPlate
                            ? content.multiPlateOrderMessage.heading
                            : content.applicantDetailsMessage.heading}
                        </H5>
                        <Paragraph1
                          color="#000"
                          dangerouslySetInnerHTML={{
                            __html: values.isMultiPlate
                              ? content.multiPlateOrderMessage.body
                              : content.applicantDetailsMessage.body,
                          }}
                        ></Paragraph1>
                        <Form.Group widths="equal">
                          {values.ownershipType === "Company" && !values.isMultiPlate && values.ownerIsApplicant && (
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  errors.companyDetails?.companyNumber &&
                                  touched.companyDetails?.companyNumber
                                }
                              >
                                <label>COMPANY NUMBER</label>
                                {errors.companyDetails?.companyNumber &&
                                  touched.companyDetails?.companyNumber && (
                                    <KpFormInputErrorMessage>
                                      {errors.companyDetails?.companyNumber}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="companyDetails.companyNumber"
                                  name="companyDetails.companyNumber"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Company Number"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyDetails?.companyNumber}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></input>
                              </KpFormField>
                              <KpFormField
                                error={
                                  errors.companyDetails?.companyOwner &&
                                  touched.companyDetails?.companyOwner
                                }
                              >
                                <label>COMPANY NAME</label>
                                {errors.companyDetails?.companyOwner &&
                                  touched.companyDetails?.companyOwner && (
                                    <KpFormInputErrorMessage>
                                      {errors.companyDetails?.companyOwner}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="companyDetails.companyOwner"
                                  name="companyDetails.companyOwner"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Company Owner"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyDetails?.companyOwner}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></input>
                              </KpFormField>
                            </Form.Group>
                          )}

                          <KpFormField
                            width={5}
                            error={
                              errors.applicantDetails?.firstName &&
                              touched.applicantDetails?.firstName
                            }
                          >
                            <label>FIRST NAME</label>
                            {errors.applicantDetails?.firstName &&
                              touched.applicantDetails?.firstName && (
                                <KpFormInputErrorMessage>
                                  {errors.applicantDetails?.firstName}
                                </KpFormInputErrorMessage>
                              )}
                            <Input
                              id="applicantDetails.firstName"
                              name="applicantDetails.firstName"
                              data-private="lipsum"
                              type="text"
                              aria-label="First Name"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.applicantDetails?.firstName}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></Input>
                          </KpFormField>
                          <KpFormField
                            width={8}
                            error={
                              errors.applicantDetails?.lastName &&
                              touched.applicantDetails?.lastName
                            }
                          >
                            <label>LAST NAME</label>
                            {errors.applicantDetails?.lastName &&
                              touched.applicantDetails?.lastName && (
                                <KpFormInputErrorMessage>
                                  {errors.applicantDetails?.lastName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="applicantDetails.lastName"
                              name="applicantDetails.lastName"
                              data-private="lipsum"
                              type="text"
                              aria-label="Last Name"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.applicantDetails?.lastName}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <KpFormField
                            error={
                              errors.applicantDetails?.email &&
                              touched.applicantDetails?.email
                            }
                          >
                            <label>EMAIL{checkout.isGifting && checkout.giftingType == GiftingType.ECard.id && checkout.giftingDetails.isForMe ? " (Recipient Email)" : ""}</label>
                            {errors.applicantDetails?.email &&
                              touched.applicantDetails?.email && (
                                <KpFormInputErrorMessage>
                                  {errors.applicantDetails?.email}
                                </KpFormInputErrorMessage>
                              )}
                                <FormikFieldEmailValidationInput
                                  as={Input}
                                  id="applicantDetails.email"
                                  name="applicantDetails.email"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Email"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  inputValid={this.state.applicantEmailIsValid}
                                  validate={this.validateApplicantEmailAddress}
                                  value={values.applicantDetails?.email}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                />
                            <InlineEmailValidatingLoader
                              active={this.state.validatingApplicantEmail}
                              inline
                              size="small"
                            />
                          </KpFormField>
                          <KpFormField
                            error={
                              errors.applicantDetails?.phone &&
                              touched.applicantDetails?.phone
                            }
                          >
                            <label>PHONE</label>
                            {errors.applicantDetails?.phone &&
                              touched.applicantDetails?.phone && (
                                <KpFormInputErrorMessage>
                                  {errors.applicantDetails?.phone}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="applicantDetails.phone"
                              name="applicantDetails.phone"
                              data-private="lipsum"
                              type="text"
                              aria-label="Phone"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              value={values.applicantDetails?.phone}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        {(values.isMultiPlate || !values.ownerIsApplicant) &&
                        <Form.Group>
                          <KpFormField>
                            <Checkbox
                              label="Keep me updated on my plate combination, new plate designs and promotions"
                              id="promoSubscribed"
                              onClick={() => {
                                setFieldValue(
                                  "promoSubscribed",
                                  !values.promoSubscribed
                                )
                              }}
                              checked={values.promoSubscribed}
                            />
                          </KpFormField>
                        </Form.Group>}<br/><br/>
                        </React.Fragment>
                        }
                        {(!values.isMultiPlate)  &&
                        <React.Fragment>
                        {
                        <React.Fragment>
                        <H2>Owners Details</H2>
                        <Paragraph1
                          color="#000"
                        >{checkout.isGifting && checkout.giftingType == GiftingType.ECard.id ? "Enter the owner’s details so we can register the plate in their name. " : "Enter the owner's details."}</Paragraph1>

                        <Form.Group widths="equal">
                          {values.ownershipType === "Company" && (
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  errors.companyDetails?.companyNumber &&
                                  touched.companyDetails?.companyNumber
                                }
                              >
                                <label>COMPANY NUMBER</label>
                                {errors.companyDetails?.companyNumber &&
                                  touched.companyDetails?.companyNumber && (
                                    <KpFormInputErrorMessage>
                                      {errors.companyDetails?.companyNumber}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="companyDetails.companyNumber"
                                  name="companyDetails.companyNumber"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Company Number"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyDetails?.companyNumber}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></input>
                              </KpFormField>
                              <KpFormField
                                error={
                                  errors.companyDetails?.companyOwner &&
                                  touched.companyDetails?.companyOwner
                                }
                              >
                                <label>COMPANY NAME</label>
                                {errors.companyDetails?.companyOwner &&
                                  touched.companyDetails?.companyOwner && (
                                    <KpFormInputErrorMessage>
                                      {errors.companyDetails?.companyOwner}
                                    </KpFormInputErrorMessage>
                                  )}
                                <input
                                  id="companyDetails.companyOwner"
                                  name="companyDetails.companyOwner"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Company Owner"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.companyDetails?.companyOwner}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></input>
                              </KpFormField>
                            </Form.Group>
                          )}

                          <KpFormField
                            width={5}
                            error={
                              errors.ownerDetails?.firstName &&
                              touched.ownerDetails?.firstName
                            }
                          >
                            <label>FIRST NAME (Legal Name)</label>
                            {errors.ownerDetails?.firstName &&
                              touched.ownerDetails?.firstName && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails.firstName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="ownerDetails.firstName"
                              name="ownerDetails.firstName"
                              data-private="lipsum"
                              type="text"
                              aria-label="Owner First Name"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ownerDetails.firstName}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                          <KpFormField
                            width={8}
                            error={
                              errors.ownerDetails?.lastName &&
                              touched.ownerDetails?.lastName
                            }
                          >
                            <label>LAST NAME (Legal Name)</label>
                            {errors.ownerDetails?.lastName &&
                              touched.ownerDetails?.lastName && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails.lastName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="ownerDetails.lastName"
                              name="ownerDetails.lastName"
                              data-private="lipsum"
                              type="text"
                              aria-label="Owner Last Name"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ownerDetails.lastName}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <KpFormField
                            error={
                              errors.ownerDetails?.email &&
                              touched.ownerDetails?.email
                            }
                          >
                            <label>EMAIL{checkout.isGifting && checkout.giftingType == GiftingType.ECard.id && !checkout.giftingDetails.isForMe ? " (Recipient Email)" : ""}</label>
                            {errors.ownerDetails?.email &&
                              touched.ownerDetails?.email && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails?.email}
                                </KpFormInputErrorMessage>
                              )}
                                <FormikFieldEmailValidationInput
                                id="ownerDetails.email"
                                name="ownerDetails.email"
                                data-private="lipsum"
                                type="text"
                                aria-label="Owner Email"
                                disabled={status.isSubmitting}
                                onChange={handleChange}
                                inputValid={this.state.ownerEmailIsValid}
                                validate={this.validateOwnerEmailAddress}
                                onBlur={handleBlur}
                                value={values.ownerDetails?.email}
                                placeholder="Type here..."
                                autoComplete="off"
                              />

                            <InlineEmailValidatingLoader
                              active={this.state.validatingOwnerEmail}
                              inline
                              size="small"
                            />
                          </KpFormField>
                          <KpFormField
                            error={
                              errors.ownerDetails?.phone &&
                              touched.ownerDetails?.phone
                            }
                          >
                            <label>PHONE</label>
                            {errors.ownerDetails?.phone &&
                              touched.ownerDetails?.phone && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails?.phone}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="ownerDetails.phone"
                              name="ownerDetails.phone"
                              data-private="lipsum"
                              type="text"
                              aria-label="Owner Phone"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ownerDetails?.phone}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        {values.ownerIsApplicant &&
                        <Form.Group>
                          <KpFormField>
                            <Checkbox
                              label="Keep me updated on my plate combination, new plate designs and promotions"
                              id="promoSubscribed"
                              onClick={() => {
                                setFieldValue(
                                  "promoSubscribed",
                                  !values.promoSubscribed
                                )
                              }}
                              checked={values.promoSubscribed}
                            />
                          </KpFormField>
                        </Form.Group>}
                        </React.Fragment>}
                        </React.Fragment>}


                        <br/>
                        <KpButton
                          id="pdf-next"
                          fullWidth="mobile"
                          loading={status.isSubmitting}
                          buttonType="primary"
                          color={theme.mobileTheme.buttonBgColor}
                          textColor={theme.mobileTheme.buttonColor}
                          type="submit"
                        >
                          NEXT
                      </KpButton>
                      </PersonalDetailsFormGroup>
                    )}

                    {!values.isMultiPlate && (
                      <PersonalDetailsFormGroup>
                        {this.props.section.includes(PersonalDetailsSection.OwnershipType) &&
                        <Form.Group widths="equal">
                          <KpFormField>
                            <H2>Is the owner an individual or a business?</H2>
                            <PersonalDetailsButtonContainer verticalAlign="bottom">
                              <Grid.Column width={16}>
                                <Grid reversed="mobile vertically">
                                  <Grid.Column
                                    mobile={16}
                                    tablet={8}
                                    computer={8}
                                    textAlign="right"
                                  >
                                    <Grid stretched>
                                      <Grid.Column mobile={16} tablet={8} computer={8}>
                                          <CheckoutSelectionCard card={individualCard} onSelect={() => this.changeOwnershipType("Individual", setFieldValue)} isSelected={this.state.individual} hideButton={true}></CheckoutSelectionCard>
                                      </Grid.Column>
                                      <Grid.Column mobile={16} tablet={8} computer={8}>
                                          <CheckoutSelectionCard card={businessCard} onSelect={() => this.changeOwnershipType("Company", setFieldValue)} isSelected={this.state.business} hideButton={true}></CheckoutSelectionCard>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                </Grid>
                              </Grid.Column>
                            </PersonalDetailsButtonContainer>
                          </KpFormField>



                        </Form.Group>}
                        {this.props.section.includes(PersonalDetailsSection.Details) && values.manufactureNow &&
                        <React.Fragment>
                        <H2>What are your personal details?</H2>
                        {values.ownershipType === "Company" && (
                          <Form.Group widths="equal">
                            <KpFormField
                              error={
                                errors.companyDetails?.companyNumber &&
                                touched.companyDetails?.companyNumber
                              }
                            >
                              <label>COMPANY NUMBER</label>
                              {errors.companyDetails?.companyNumber &&
                                touched.companyDetails?.companyNumber && (
                                  <KpFormInputErrorMessage>
                                    {errors.companyDetails?.companyNumber}
                                  </KpFormInputErrorMessage>
                                )}
                              <input
                                id="companyDetails.companyNumber"
                                name="companyDetails.companyNumber"
                                data-private="lipsum"
                                type="text"
                                aria-label="Company Number"
                                disabled={status.isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyDetails?.companyNumber}
                                placeholder="Type here..."
                                autoComplete="off"
                              ></input>
                            </KpFormField>
                            <KpFormField
                              error={
                                errors.companyDetails?.companyOwner &&
                                touched.companyDetails?.companyOwner
                              }
                            >
                              <label>COMPANY NAME</label>
                              {errors.companyDetails?.companyOwner &&
                                touched.companyDetails?.companyOwner && (
                                  <KpFormInputErrorMessage>
                                    {errors.companyDetails?.companyOwner}
                                  </KpFormInputErrorMessage>
                                )}
                              <input
                                id="companyDetails.companyOwner"
                                name="companyDetails.companyOwner"
                                data-private="lipsum"
                                type="text"
                                aria-label="Company Owner"
                                disabled={status.isSubmitting}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.companyDetails?.companyOwner}
                                placeholder="Type here..."
                                autoComplete="off"
                              ></input>
                            </KpFormField>
                          </Form.Group>
                        )}
                        <Form.Group widths="equal">
                          <KpFormField
                            width={5}
                            error={
                              errors.ownerDetails?.firstName &&
                              touched.ownerDetails?.firstName
                            }
                          >
                            <label>FIRST NAME (Legal Name)</label>
                            {errors.ownerDetails?.firstName &&
                              touched.ownerDetails?.firstName && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails.firstName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="ownerDetails.firstName"
                              name="ownerDetails.firstName"
                              data-private="lipsum"
                              type="text"
                              aria-label="Owner First Name"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ownerDetails.firstName}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                          <KpFormField
                            width={8}
                            error={
                              errors.ownerDetails?.lastName &&
                              touched.ownerDetails?.lastName
                            }
                          >
                            <label>LAST NAME (Legal Name)</label>
                            {errors.ownerDetails?.lastName &&
                              touched.ownerDetails?.lastName && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails.lastName}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="ownerDetails.lastName"
                              name="ownerDetails.lastName"
                              data-private="lipsum"
                              type="text"
                              aria-label="Owner Last Name"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ownerDetails.lastName}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        <Form.Group widths="equal">
                          <KpFormField
                            error={
                              errors.ownerDetails?.email &&
                              touched.ownerDetails?.email
                            }
                          >
                            <label>EMAIL</label>
                            {errors.ownerDetails?.email &&
                              touched.ownerDetails?.email && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails?.email}
                                </KpFormInputErrorMessage>
                              )}
                                <FormikFieldEmailValidationInput
                                id="ownerDetails.email"
                                name="ownerDetails.email"
                                data-private="lipsum"
                                type="text"
                                aria-label="Owner Email"
                                disabled={status.isSubmitting}
                                onChange={handleChange}
                                inputValid={this.state.ownerEmailIsValid}
                                validate={this.validateOwnerEmailAddress}
                                onBlur={handleBlur}
                                value={values.ownerDetails?.email}
                                placeholder="Type here..."
                                autoComplete="off"
                              />

                            <InlineEmailValidatingLoader
                              active={this.state.validatingOwnerEmail}
                              inline
                              size="small"
                            />
                          </KpFormField>
                          <KpFormField
                            error={
                              errors.ownerDetails?.phone &&
                              touched.ownerDetails?.phone
                            }
                          >
                            <label>PHONE</label>
                            {errors.ownerDetails?.phone &&
                              touched.ownerDetails?.phone && (
                                <KpFormInputErrorMessage>
                                  {errors.ownerDetails?.phone}
                                </KpFormInputErrorMessage>
                              )}
                            <input
                              id="ownerDetails.phone"
                              name="ownerDetails.phone"
                              data-private="lipsum"
                              type="text"
                              aria-label="Owner Phone"
                              disabled={status.isSubmitting}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.ownerDetails?.phone}
                              placeholder="Type here..."
                              autoComplete="off"
                            ></input>
                          </KpFormField>
                        </Form.Group>
                        {values.ownerIsApplicant && (
                          <Form.Group style={{ padding: "5px 0 10px 0" }}>
                            <KpFormField>
                              <Checkbox
                                label="Keep me updated on my plate combination, new plate designs and promotions"
                                id="promoSubscribed"
                                onClick={() => {
                                  setFieldValue(
                                    "promoSubscribed",
                                    !values.promoSubscribed
                                  )
                                }}
                                checked={values.promoSubscribed}
                              />
                            </KpFormField>
                          </Form.Group>
                        )}
                          <PersonalDetailsButtonContainer verticalAlign="bottom">
                            <Grid.Column width={16}>
                              <Grid reversed="mobile vertically">
                                <Grid.Column
                                  mobile={16}
                                  tablet={8}
                                  computer={8}
                                  textAlign="right"
                                >
                                  {values.manufactureNow ?
                                  <KpButton
                                    id="pdf-next"
                                    fullWidth="mobile"
                                    loading={status.isSubmitting}
                                    buttonType="primary"
                                    color={theme.mobileTheme.buttonBgColor}
                                    textColor={theme.mobileTheme.buttonColor}
                                    type="button"
                                    link={this.isObjectHasEmptyAttributes(values.ownerDetails) ? "" : "/personal-details/#address"}
                                    disabled={this.isObjectHasEmptyAttributes(values.ownerDetails)}
                                  >
                                    NEXT
                                  </KpButton>:
                                  <KpButton
                                    id="pdf-next"
                                    fullWidth="mobile"
                                    loading={status.isSubmitting}
                                    buttonType="primary"
                                    color={theme.mobileTheme.buttonBgColor}
                                    textColor={theme.mobileTheme.buttonColor}
                                    type="submit"
                                    disabled={this.isObjectHasEmptyAttributes(values.ownerDetails)}
                                  >
                                    NEXT
                                  </KpButton>}
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </PersonalDetailsButtonContainer>
                        </React.Fragment>}

                        {this.props.section.includes(PersonalDetailsSection.Address) &&
                        <React.Fragment>
                        <H2>And what is your address?</H2>
                        {this.props.checkoutState.manufactureNow && (
                          <>
                            <AddressFinderFormGroup
                              show={
                                !this.props.checkoutState.ownerAddress
                                  .isManualAddress
                              }
                              widths="equal"
                            >
                              <KpFormField
                                error={
                                  errors.ownerAddress?.street &&
                                  touched.ownerAddress?.fullAddress
                                }
                              >
                                <AddressSearchTitleSection>
                                  <label>ADDRESS</label>
                                  <AddressToggerLinkDiv
                                    onClick={() =>
                                      this.toggleManualAddress(setFieldValue)
                                    }
                                  >
                                    ENTER ADDRESS MANUALLY
                                </AddressToggerLinkDiv>
                                  {this.props.checkoutState.manufactureNow &&
                                    values.ownerAddress.fullAddress != '' &&
                                    this.isAddressHasEmptyAttributes(values.ownerAddress) && (
                                      <KpFormInputErrorMessage>
                                        Please select an address
                                      </KpFormInputErrorMessage>
                                    )}
                                </AddressSearchTitleSection>

                                <AddressSearchAutoComplete
                                  id="ownerAddress.fullAddress"
                                  autoComplete="new-password"
                                  loading={this.state.searchingAddress}
                                  name="ownerAddress.fullAddress"
                                  type="text"
                                  placeholder='Type here...'
                                  aria-label="Full Addr"
                                  disabled={status.isSubmitting}
                                  minCharacters={2}
                                  onSearchChange={(
                                    event: SyntheticEvent,
                                    data: any
                                  ) => {
                                    setFieldValue(
                                      "ownerAddress.fullAddress",
                                      data.value
                                    )
                                    setFieldValue("ownerAddress.unit", "")
                                    setFieldValue("ownerAddress.city", "")
                                    setFieldValue("ownerAddress.postCode", "")
                                    setFieldValue("ownerAddress.street", "")
                                    setFieldValue("ownerAddress.suburb", "")
                                    this.handleAddressSearchChange(event, data)
                                  }}
                                  onResultSelect={(
                                    event: SyntheticEvent,
                                    data: any
                                  ) => {
                                    this.setState({
                                      addressValue: data.result.title,
                                      searchingAddress: true,
                                    })
                                    setFieldValue(
                                      "ownerAddress.fullAddress",
                                      data.result.title
                                    )
                                    const token = this.props
                                      .addressValidationToken
                                    AddressFinderService.experianAddressMetadata(
                                      token,
                                      data.result.id
                                    ).then((result: any) => {
                                      const unit = result.address_line_2
                                        ? result.address_line_1
                                        : ""
                                      const addressLine1 = result.address_line_2
                                        ? result.address_line_2
                                        : result.address_line_1

                                      setFieldValue("ownerAddress.unit", unit)
                                      setFieldValue(
                                        "ownerAddress.city",
                                        result.region
                                      )
                                      setFieldValue(
                                        "ownerAddress.postCode",
                                        result.postal_code
                                      )
                                      setFieldValue(
                                        "ownerAddress.street",
                                        addressLine1
                                      )
                                      setFieldValue(
                                        "ownerAddress.suburb",
                                        result.locality
                                      )
                                      this.setState({
                                        addressValue: data.result.title,
                                        searchingAddress: false,
                                      })
                                    })
                                  }}
                                  showNoResults={false}
                                  value={this.state.addressValue}
                                  results={this.state.addressResults}
                                  resultRenderer={addressResultRender}
                                />
                              </KpFormField>
                            </AddressFinderFormGroup>

                            {this.props.checkoutState.ownerAddress
                              .isManualAddress && (
                                <PersonalDetailsManualAddressContainer>
                                  <Grid>
                                    <Grid.Column textAlign="left" width={8}>
                                      <PersonalDetailsManualAddressContainerHeading>
                                        Manual Address Input
                                      </PersonalDetailsManualAddressContainerHeading>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" width={8}>
                                      <PersonalDetailsContainerLink
                                        onClick={() =>
                                          this.toggleManualAddress(setFieldValue)
                                        }
                                      >
                                        USE ADDRESS LOOKUP
                                    </PersonalDetailsContainerLink>
                                    </Grid.Column>
                                  </Grid>
                                  <Form.Group widths="equal">
                                    <KpFormField
                                      error={
                                        errors.ownerAddress?.building &&
                                        touched.ownerAddress?.building
                                      }
                                    >
                                      <label>BUILDING NAME</label>
                                      {errors.ownerAddress?.building &&
                                        touched.ownerAddress?.building && (
                                          <KpFormInputErrorMessage>
                                            {errors.ownerAddress?.building}
                                          </KpFormInputErrorMessage>
                                        )}
                                      <input
                                        id="ownerAddress.building"
                                        name="ownerAddress.building"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="Owner Address - building"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerAddress?.building}
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      ></input>
                                    </KpFormField>
                                    <KpFormField
                                      error={
                                        errors.ownerAddress?.unit &&
                                        touched.ownerAddress?.unit
                                      }
                                    >
                                      <label>UNIT #, FLOOR, LEVEL</label>
                                      {errors.ownerAddress?.unit &&
                                        touched.ownerAddress?.unit && (
                                          <KpFormInputErrorMessage>
                                            {errors.ownerAddress?.unit}
                                          </KpFormInputErrorMessage>
                                        )}
                                      <input
                                        id="ownerAddress.unit"
                                        name="ownerAddress.unit"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="Owner Address - unit"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerAddress?.unit}
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      ></input>
                                    </KpFormField>
                                  </Form.Group>
                                  <Form.Group widths="equal">
                                    <KpFormField
                                      error={
                                        errors.ownerAddress?.street &&
                                        touched.ownerAddress?.street
                                      }
                                    >
                                      <label>STREET ADDRESS</label>
                                      {errors.ownerAddress?.street &&
                                        touched.ownerAddress?.street && (
                                          <KpFormInputErrorMessage>
                                            {errors.ownerAddress?.street}
                                          </KpFormInputErrorMessage>
                                        )}
                                      <input
                                        id="ownerAddress.street"
                                        name="ownerAddress.street"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="Owner Address - street"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerAddress?.street}
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      ></input>
                                    </KpFormField>
                                    <KpFormField
                                      error={
                                        errors.ownerAddress?.suburb &&
                                        touched.ownerAddress?.suburb
                                      }
                                    >
                                      <label>SUBURB</label>
                                      {errors.ownerAddress?.suburb &&
                                        touched.ownerAddress?.suburb && (
                                          <KpFormInputErrorMessage>
                                            {errors.ownerAddress?.suburb}
                                          </KpFormInputErrorMessage>
                                        )}
                                      <input
                                        id="ownerAddress.suburb"
                                        name="ownerAddress.suburb"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="Owner Address - suburb"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerAddress?.suburb}
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      ></input>
                                    </KpFormField>
                                  </Form.Group>
                                  <Form.Group widths="equal">
                                    <KpFormField
                                      error={
                                        errors.ownerAddress?.city &&
                                        touched.ownerAddress?.city
                                      }
                                    >
                                      <label>CITY OR TOWN</label>
                                      {errors.ownerAddress?.city &&
                                        touched.ownerAddress?.city && (
                                          <KpFormInputErrorMessage>
                                            {errors.ownerAddress?.city}
                                          </KpFormInputErrorMessage>
                                        )}
                                      <input
                                        id="ownerAddress.city"
                                        name="ownerAddress.city"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="Owner Address - city"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerAddress?.city}
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      ></input>
                                    </KpFormField>
                                    <KpFormField
                                      error={
                                        errors.ownerAddress?.postCode &&
                                        touched.ownerAddress?.postCode
                                      }
                                    >
                                      <label>POSTCODE</label>
                                      {errors.ownerAddress?.postCode &&
                                        touched.ownerAddress?.postCode && (
                                          <KpFormInputErrorMessage>
                                            {errors.ownerAddress?.postCode}
                                          </KpFormInputErrorMessage>
                                        )}
                                      <input
                                        id="ownerAddress.postCode"
                                        name="ownerAddress.postCode"
                                        data-private="lipsum"
                                        type="text"
                                        aria-label="Owner Address - postcode"
                                        disabled={status.isSubmitting}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ownerAddress?.postCode}
                                        placeholder="Type here..."
                                        autoComplete="off"
                                      ></input>
                                    </KpFormField>
                                  </Form.Group>
                                </PersonalDetailsManualAddressContainer>
                              )}

                              <PersonalDetailsButtonContainer verticalAlign="bottom">
                                <Grid.Column width={16}>
                                  <Grid reversed="mobile vertically">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={8}
                                      computer={8}
                                      textAlign="right"
                                    >
                                      <KpButton
                                        id="pdf-next"
                                        fullWidth="mobile"
                                        loading={status.isSubmitting}
                                        buttonType="primary"
                                        color={theme.mobileTheme.buttonBgColor}
                                        textColor={theme.mobileTheme.buttonColor}
                                        type="button"
                                        link={this.isAddressHasEmptyAttributes(values.ownerAddress) ? "" : "/personal-details/#liscense"}
                                        disabled={this.isAddressHasEmptyAttributes(values.ownerAddress)}
                                      >
                                        NEXT
                                    </KpButton>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                              </PersonalDetailsButtonContainer>
                          </>
                        )}
                        </React.Fragment>}


                        {this.props.checkoutState.manufactureNow && (
                          <div>
                            {this.props.section.includes(PersonalDetailsSection.Liscense) &&
                            <React.Fragment>
                            <H2>What is your Drivers License Number and date of birth?</H2>
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  errors.vehicleDetails?.driverLicense &&
                                  touched.vehicleDetails?.driverLicense
                                }
                              >
                                <label>DRIVER LICENSE NUMBER</label>
                                {errors.vehicleDetails?.driverLicense &&
                                  touched.vehicleDetails?.driverLicense && (
                                    <KpFormInputErrorMessage>
                                      {errors.vehicleDetails?.driverLicense}
                                    </KpFormInputErrorMessage>
                                  )}
                                <InputCapStyle
                                  id="vehicleDetails.driverLicense"
                                  name="vehicleDetails.driverLicense"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Owner Driver License"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.vehicleDetails?.driverLicense.toUpperCase()}
                                  placeholder="DZ123456"
                                  autoComplete="off"
                                ></InputCapStyle>
                              </KpFormField>
                              <KpFormField
                                error={
                                  errors.vehicleDetails?.dob &&
                                  touched.vehicleDetails?.dob
                                }
                              >
                                <label>OWNER DATE OF BIRTH</label>
                                {errors.vehicleDetails?.dob &&
                                  touched.vehicleDetails?.dob && (
                                    <KpFormInputErrorMessage>
                                      {errors.vehicleDetails?.dob}
                                    </KpFormInputErrorMessage>
                                  )}
                                <PersonalDetailsDOBContainer>
                                  <KpFormDropdown
                                    placeholder="Day"
                                    fluid
                                    selection
                                    search
                                    options={Days(
                                      moment(
                                        new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                      )
                                    )}
                                    icon="chevron down"
                                    id="vehicleDetails.dobDay"
                                    name="vehicleDetails.dobDay"
                                    disabled={status.isSubmitting}
                                    value={moment(
                                      new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                    ).format("D")}
                                    onChange={(el: any, data: any) => {
                                      let currentDate = moment(
                                        new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                      )
                                      const day = parseInt(data.value)
                                      const newDate = currentDate.date(day)
                                      setFieldValue(
                                        "vehicleDetails.dob",
                                        newDate.toDate()
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    backgroundColor={theme.brand.colors.white}
                                  />
                                  <KpFormDropdown
                                    placeholder="Month"
                                    fluid
                                    selection
                                    search
                                    options={Months}
                                    icon="chevron down"
                                    id="vehicleDetails.dobMonth"
                                    name="vehicleDetails.dobMonth"
                                    disabled={status.isSubmitting}
                                    value={moment(
                                      new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                    ).format("M")}
                                    onChange={(el: any, data: any) => {
                                      let currentDate = moment(
                                        new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                      )
                                      const month = parseInt(data.value) - 1
                                      const newDate = currentDate.month(month)
                                      setFieldValue(
                                        "vehicleDetails.dob",
                                        newDate.toDate()
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    backgroundColor={theme.brand.colors.white}
                                  />
                                  <KpFormDropdown
                                    placeholder="Year"
                                    fluid
                                    selection
                                    search
                                    options={Years()}
                                    icon="chevron down"
                                    id="vehicleDetails.dobYear"
                                    name="vehicleDetails.dobYear"
                                    disabled={status.isSubmitting}
                                    value={moment(
                                      new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                    )
                                      .year()
                                      .toString()}
                                    onChange={(el: any, data: any) => {
                                      let currentDate = moment(
                                        new Date(values.vehicleDetails.dob == undefined ? new Date() : values.vehicleDetails.dob.toString())
                                      )
                                      const year = parseInt(data.value)
                                      const newDate = currentDate.year(year)
                                      setFieldValue(
                                        "vehicleDetails.dob",
                                        newDate.toDate()
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    backgroundColor={theme.brand.colors.white}
                                  />
                                </PersonalDetailsDOBContainer>
                              </KpFormField>
                              <KpFormField>
                              <PersonalDetailsButtonContainer verticalAlign="bottom">
                                  <Grid.Column width={16}>
                                    <Grid reversed="mobile vertically">
                                      <Grid.Column
                                        mobile={16}
                                        tablet={8}
                                        computer={8}
                                        textAlign="right"
                                      >
                                        <KpButton
                                          id="pdf-next"
                                          fullWidth="mobile"
                                          loading={status.isSubmitting}
                                          buttonType="primary"
                                          color={theme.mobileTheme.buttonBgColor}
                                          textColor={theme.mobileTheme.buttonColor}
                                          type="button"
                                          link={values.vehicleDetails.driverLicense != "" ? "/personal-details/#vehicle-registration" : ""}
                                          disabled={values.vehicleDetails.driverLicense === ""}
                                        >
                                          NEXT
                                      </KpButton>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                </PersonalDetailsButtonContainer>
                                </KpFormField>
                            </Form.Group>
                            </React.Fragment>}

                            <React.Fragment>
                            {this.props.section.includes(PersonalDetailsSection.VehicleRegistration) &&
                            <React.Fragment>

                            <H2>{content.vehicleDetailsMessage.heading}</H2>
                            <Paragraph1
                              color={theme.brand.colors.black}
                              dangerouslySetInnerHTML={{
                                __html: content.vehicleDetailsMessage.body,
                              }}
                            ></Paragraph1>
                            <Form.Group widths="equal">
                              <KpFormField
                                error={
                                  errors.vehicleDetails?.existingCombination &&
                                  touched.vehicleDetails?.existingCombination
                                }
                              >
                                <label>EXISTING PLATE NUMBER</label>
                                {errors.vehicleDetails?.existingCombination &&
                                  touched.vehicleDetails?.existingCombination && (
                                    <KpFormInputErrorMessage>
                                      {errors.vehicleDetails?.existingCombination}
                                    </KpFormInputErrorMessage>
                                  )}
                                <InputCapStyle
                                  id="vehicleDetails.existingCombination"
                                  name="vehicleDetails.existingCombination"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Owner Vehicle Existing Combination"
                                  maxLength={6}
                                  disabled={status.isSubmitting || disableEditExistingPlate}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={
                                    values.vehicleDetails?.existingCombination.toUpperCase()
                                  }
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></InputCapStyle>
                              </KpFormField>
                              <KpFormField
                                error={
                                  errors.vehicleDetails?.vehicleMake &&
                                  touched.vehicleDetails?.vehicleMake
                                }
                              >
                                <label>VEHICLE MAKE</label>
                                {errors.vehicleDetails?.vehicleMake &&
                                  touched.vehicleDetails?.vehicleMake && (
                                    <KpFormInputErrorMessage>
                                      {errors.vehicleDetails?.vehicleMake}
                                    </KpFormInputErrorMessage>
                                  )}
                                <InputCapStyle
                                  id="vehicleDetails.vehicleMake"
                                  name="vehicleDetails.vehicleMake"
                                  data-private="lipsum"
                                  type="text"
                                  aria-label="Vehicle Make"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.vehicleDetails?.vehicleMake.toUpperCase()}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></InputCapStyle>
                              </KpFormField>
                              <KpFormField
                                error={
                                  errors.vehicleDetails?.vehicleModel &&
                                  touched.vehicleDetails?.vehicleModel
                                }
                              >
                                <label>VEHICLE MODEL</label>
                                {errors.vehicleDetails?.vehicleModel &&
                                  touched.vehicleDetails?.vehicleModel && (
                                    <KpFormInputErrorMessage>
                                      {errors.vehicleDetails?.vehicleModel}
                                    </KpFormInputErrorMessage>
                                  )}
                                <InputCapStyle
                                  id="vehicleDetails.vehicleModel"
                                  name="vehicleDetails.vehicleModel"
                                  type="text"
                                  data-private="lipsum"
                                  aria-label="Vehicle Model"
                                  disabled={status.isSubmitting}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.vehicleDetails?.vehicleModel.toUpperCase()}
                                  placeholder="Type here..."
                                  autoComplete="off"
                                ></InputCapStyle>
                              </KpFormField>
                              <KpFormField>
                              <PersonalDetailsButtonContainer verticalAlign="bottom">
                                <Grid.Column width={16}>
                                  <Grid reversed="mobile vertically">
                                    <Grid.Column
                                      mobile={16}
                                      tablet={8}
                                      computer={8}
                                      textAlign="right"
                                    >
                                      <KpButton
                                        id="pdf-next"
                                        fullWidth="mobile"
                                        //disabled={this.state.searchingAddress}
                                        loading={status.isSubmitting}
                                        buttonType="primary"
                                        color={theme.mobileTheme.buttonBgColor}
                                        textColor={theme.mobileTheme.buttonColor}
                                        type="submit"
                                        disabled={this.isObjectHasEmptyAttributes(values.vehicleDetails)}
                                      >
                                        NEXT
                                    </KpButton>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                              </PersonalDetailsButtonContainer>
                              </KpFormField>
                            </Form.Group>
                            </React.Fragment>}
                            </React.Fragment>
                          </div>
                        )}
                      </PersonalDetailsFormGroup>
                    )}
                  </Grid.Column>
                </Grid>
                {this.state.reCaptchaError && <KpFormErrorMessageLeft>{this.state.reCaptchaError}</KpFormErrorMessageLeft>}
                <div className="g-recaptcha-v2" data-sitekey={this.props.reCaptchaSiteKeyV2} id="g-recaptcha_personalDetails-v2" style={{display:"none"}}></div>
                <PersonalDetailsButtonContainer verticalAlign="bottom">
                  <Grid.Column width={16}>
                    <Grid reversed="mobile vertically">
                      <Grid.Column
                        mobile={16}
                        tablet={8}
                        computer={8}
                        textAlign="right"
                      >
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </PersonalDetailsButtonContainer>
              </Form>
            )}
        </Formik>
        )}</ReCaptcha>
      </PersonalDetailsFormContainer>
    )
  }
}

export default MobilePersonalDetailsForm
